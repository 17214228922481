/**
 * Calculate the average occupancy across all timestamps.
 * 
 * @param {Array} data - The parsed CSV data, where each entry contains a timestamp and occupancy value.
 * @returns {string|number} - The rounded average occupancy or 'N/A' if data is insufficient.
 */
export const CalcAverageOccupancy = (data) => {
    // Check if the data is valid and contains entries
    if (!Array.isArray(data) || data.length === 0) {
        console.error("Invalid or empty data provided to CalcAverageOccupancy");
        return 'N/A'; // Return 'N/A' if no valid data is available
    }

    // Calculate total occupancy and count of unique days
    const totalOccupancy = data.reduce((sum, entry) => sum + Number(entry.occupancy), 0);
    const uniqueDays = new Set(data.map(entry => entry.timestamp)).size;

    // Calculate and return the average if there are unique days, else return 'N/A'
    const average = uniqueDays > 0 ? totalOccupancy / uniqueDays : null;
    return average !== null ? Math.round(average) : 'N/A';
};

/**
 * Calculate the average occupancy per room.
 * 
 * @param {Array} data - The filtered data containing room IDs and occupancy values.
 * @returns {Object} - An object mapping room IDs to their respective average occupancies.
 */
export const CalcRoomAvgOccupancy = (data) => {
    // Check if data is valid
    if (!Array.isArray(data) || data.length === 0) {
        console.error("Invalid or empty data provided to CalcRoomAvgOccupancy");
        return {}; // Return an empty object if no valid data
    }

    // Group the data by room_id
    const occupancyByRoom = data.reduce((acc, entry) => {
        const roomId = entry.room_id;
        const occupancy = Number(entry.occupancy);

        // Ensure roomId and occupancy exist and are valid
        if (!roomId || isNaN(occupancy)) {
            console.warn(`Invalid entry encountered: ${JSON.stringify(entry)}`);
            return acc; // Skip this entry if it's invalid
        }

        // Initialize room array if not already present
        if (!acc[roomId]) {
            acc[roomId] = [];
        }

        acc[roomId].push(occupancy); // Append the occupancy for this room
        return acc;
    }, {});

    // Calculate the average occupancy for each room
    const averageOccupancyByRoom = {};
    for (const [roomId, occupancies] of Object.entries(occupancyByRoom)) {
        const totalOccupancy = occupancies.reduce((sum, occ) => sum + occ, 0);
        const average = occupancies.length > 0 ? totalOccupancy / occupancies.length : null;
        averageOccupancyByRoom[roomId] = average !== null ? Math.round(average) : 'N/A';
    }

    // Return an object where each room ID maps to its average occupancy
    return averageOccupancyByRoom;
};
