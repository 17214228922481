import { toZonedTime } from "date-fns-tz";
import { DateTime } from "luxon";

/**
 * Filters and consolidates data based on a dynamic timeframe (slider-based).
 *
 * @param {Array} data - The parsed occupancy data.
 * @param {number} startHours - The starting hours to filter data for.
 * @param {number} endHours - The ending hours to filter data for.
 * @returns {Array} Filtered or consolidated data.
 */

export const filterDataByTimeframe = async (
  data,
  startHours,
  endHours,
  timeZone
) => {
  const startDate = toZonedTime(new Date(), timeZone);
  //const startDate = new Date();
  
  // Adjust start date based on weekend logic
  const dayOfWeek = startDate.getDay();
  if (dayOfWeek === 0) {
    // Sunday -> Adjust to previous Friday 6 PM
    startDate.setDate(startDate.getDate() - 2);
    startDate.setHours(18, 0, 0, 0);
  } else if (dayOfWeek === 6) {
    // Saturday -> Adjust to previous Friday 6 PM
    startDate.setDate(startDate.getDate() - 1);
    startDate.setHours(18, 0, 0, 0);
  }

  // Calculate the end time based on the number of hours
  // Adjust endDate and startRangeDate based on time zone offset
  const startOffset = 0//moment.tz(startDate, timeZone).utcOffset() / 60; // Get offset in hours

  // Calculate the end time and start range date based on adjusted hours and offsets
  const endDate = new Date(
    startDate.getTime() - endHours * 60 * 60 * 1000
  );
  const startRangeDate = new Date(
    startDate.getTime() - startHours * 60 * 60 * 1000
  );

  /**
   * Consolidates data by date and room by averaging occupancy values.
   *
   * @param {Array} filteredData - The filtered occupancy data.
   * @returns {Array} Consolidated data with averaged occupancy values.
   */
  const consolidateDataByDateAndRoom = (filteredData) => {
    const consolidatedData = filteredData.reduce((acc, entry) => {
      const date = new Date(entry.timestamp);
      const formattedDate = `${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(date.getDate()).padStart(2, "0")}/${date.getFullYear()}`;
      const roomId = entry.room_id;

      if (!acc[formattedDate]) acc[formattedDate] = {};
      if (!acc[formattedDate][roomId])
        acc[formattedDate][roomId] = { totalOccupancy: 0, count: 0 };

      acc[formattedDate][roomId].totalOccupancy += Number(entry.occupancy);
      acc[formattedDate][roomId].count += 1;

      return acc;
    }, {});

    return Object.entries(consolidatedData).flatMap(([date, rooms]) =>
      Object.entries(rooms).map(([roomId, data]) => ({
        timestamp: date,
        room_id: roomId,
        occupancy:
          data.count > 0 ? Math.round(data.totalOccupancy / data.count) : "N/A",
      }))
    );
  };

  const convertToTimeAtSameLocalTime = (timestamp) => {
    // Parse the timestamp into a Luxon DateTime object in the PST timezone
    const entryTime = DateTime.fromJSDate(timestamp).setZone(timeZone);

    // Convert the time to Frankfurt timezone while keeping the same local time
    const entryTimeAtSameLocalTime = entryTime.setZone(timeZone, {
      keepLocalTime: true,
    });

    return entryTimeAtSameLocalTime.toJSDate(); // Convert back to JavaScript Date object if needed
  };

  const filteredData = data.filter((entry) => {
    const entryTime = convertToTimeAtSameLocalTime(new Date(entry.timestamp));
    //console.log("adjustedstartRangeDate",adjustedstartRangeDate);
    //console.log("adjustedEndDate",adjustedEndDate);
    return entryTime <= startRangeDate && entryTime >= endDate;
  });

  // Consolidate data if the range exceeds 48 hours
  if (startHours > 48 || endHours > 48) {
    return consolidateDataByDateAndRoom(filteredData);
  }
  return filteredData; // Return filtered data for timeframes <= 48 hours
};
