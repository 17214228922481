import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { treemapSquarify } from 'd3';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels); // Register the necessary ChartJS elements

/**
 * Helper function to generate a gradient of purple shades for the pie chart.
 * 
 * @param {number} numSegments - The number of segments in the pie chart.
 * @param {string} color1 - Colors for the chart
 * @returns {Array} - An array of RGBA colors for the chart segments.
 */
const hexToRgbArray = (hex) => {
  // Remove the hash (#) if present
  const sanitizedHex = hex.replace(/^#/, '');

  // Convert the sanitized hex string to RGB values
  const r = parseInt(sanitizedHex.substring(0, 2), 16);
  const g = parseInt(sanitizedHex.substring(2, 4), 16);
  const b = parseInt(sanitizedHex.substring(4, 6), 16);

  return [r, g, b];
};
const generateMagentaGradient = (numSegments, color1) => {
  const startColor = hexToRgbArray(color1);//[159, 41, 42]; // Base color (RGB)

  const gradientColors = [];
  for (let i = 0; i < numSegments; i++) {
    const factor = i / numSegments; // Determines the lightness based on segment position
    const color = `rgba(${startColor[0] + factor * (255 - startColor[0])}, 
                        ${startColor[1] + factor * (255 - startColor[1])}, 
                        ${startColor[2] + factor * (255 - startColor[2])}, 
                        0.8)`; // Consistent opacity of 0.8
    gradientColors.push(color);
  }
  return gradientColors;
};

const PieChart = ({ data, time, color1 }) => {
  // Error handling: Check if data is present and contains valid datasets and labels
  if (!data || !data.datasets || !data.datasets.length || !data.labels || !data.labels.length) {
    return <p>No data available for the pie chart.</p>; // Display message if no data
  }
  // Generate magenta gradient colors based on the number of labels
  const pieColors = generateMagentaGradient(data.labels.length, color1);

  // Calculate the highest value and its label
  const numericalData = data.datasets[0].data.map(value => parseFloat(value));
  const highestValueIndex = numericalData.indexOf(Math.max(...numericalData));
  const highestValue = numericalData[highestValueIndex];
  const highestLabel = data.labels[highestValueIndex];

  // Add offsets to highlight the highest segment
  const segmentOffsets = numericalData.map((_, index) =>
    index === highestValueIndex ? 25 : 0 // 20px offset for the highest segment, 0 for others
  );

  let timeframe;
  if (time[1] > 48 || time[0] > 48) {
    timeframe = `${Math.round((Number(time[1]) - Number(time[0])) / 24)}d`;
  } else if (Number(time[1]) - Number(time[0]) < 1) {
    timeframe = `${(Number(time[1]) - Number(time[0])) * 60 * 60}s`;
  } else {
    timeframe = `${Number(time[1]) - Number(time[0])}hr(s)`;
  };

  return (
    <div style={styles.pieChartContainer}>
      <h2 style={styles.title}>Occupancy Distribution Pie Chart ({timeframe})</h2>
      {/*<div style={styles.pieChartContainer}>*/}
      <Pie
        data={{
          ...data,
          datasets: [
            {
              ...data.datasets[0], // Retain original dataset structure
              backgroundColor: pieColors, // Apply gradient colors to the segments
              hoverBackgroundColor: pieColors, // Hover effect with the same colors
              cutout: '50%', //Donut effect
              offset: segmentOffsets, // Add dynamic offsets for each segment
            },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false, // Adjust chart to fit container dimensions
          plugins: {
            legend: {
              display: false,
              position: 'right', // Position the legend on the right side
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const value = context.raw;
                  return `${context.label}: ${value}%`; // Show percentage value in the tooltip
                },
              },
            },
            datalabels: {
              //display: treemapSquarify,
              color: 'black', // Set label text color
              font: {
                size: 8, // Set font size for labels
              },
              formatter: (value, context) => {
                // Show only the label, omitting percentage value in the chart itself
                //return `${context.chart.data.labels[context.dataIndex]}`;
                return `${Math.round(value)}%`;
              },
            },
          },
        }}
      />
      <div style={styles.message}>
        <h4>
          <span style={{ color: pieColors[highestValueIndex] }}>{highestValue}%</span> of the rooms were occupied by{' '}
          <span style={{ color: pieColors[highestValueIndex] }}>{highestLabel} people</span>
        </h4>
      </div>

      {/*</div>*/}
    </div>
  );
};

// Styles for the PieChart component
const styles = {
  pieChartContainer: {
    width: '100%', // Full width to fit the parent container
    height: '63%',
    position: 'relative', // Ensures the pie chart takes up the entire container height
  },
  title: {
    color: 'rgba(0, 0, 0, 0.9)', // Title color in near-black
  },
  message: {
    color: '#000000'
  }
};

export default PieChart;
