// ForgotPassword.js
import React, { useState } from "react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_LOGIN_URL}/api/auth/forgot-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );

    if (response.ok) {
      setMessage("If this email is associated with an account, a password reset link has been sent.");
      setEmail("");  // Clear email after submission
    } else {
      const errorData = await response.json();
      setError(errorData.message || "Failed to send password reset link.");
    }
  };

  const styles = {
    containerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "linear-gradient(to left, #005BA7, #f3f1f7, #005BA7)",
      color: "white",
    },
    formContainerStyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      border: "2px solid #005BA7",
      width: "350px",
    },
    formStyle: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      width: "100%",
    },
    inputStyle: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    buttonStyle: {
      padding: "10px",
      backgroundColor: "#005BA7",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.containerStyle}>
      <div style={styles.formContainerStyle}>
        <h2>Forgot Password</h2>
        {message && <p style={{ color: "green" }}>{message}</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <form onSubmit={handleSubmit} style={styles.formStyle}>
          <div style={{ marginBottom: "15px" }}>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              style={styles.inputStyle}
              required
            />
          </div>
          <button type="submit" style={styles.buttonStyle}>
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
