import React, { useEffect, useState, useCallback } from "react";
import "./App.css"; // Import custom styles
import Login from "./components/Login";
import VerifyEmail from "./components/VerifyEmail"; // Import VerifyEmail component
import Switch from "react-switch";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactSelect, { components } from "react-select";
import { TimeframeSlider, LiveModeSlider } from "./components/TimeframeSlider";
import PouchDB from "pouchdb";
import Joyride from "react-joyride";
import { format, set } from "date-fns"; // Import date formatting library
import Floorplan from "./components/Floorplan";
import LineChart from "./components/LineChart";
import {
  CalcAverageOccupancy,
  CalcRoomAvgOccupancy,
} from "./components/AverageOccupancy";
import CalendarPlot from "./components/CalendarPlot";
import PieChart from "./components/PieChart";
import { filterDataByTimeframe } from "./utils/dataProcessing";
import GridLayout from "react-grid-layout"; // Import react-grid-layout
import BarChart from "./components/BarPlot";
import MapComponent from "./components/MapComponent";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";
import Sidebar from "./components/Sidebar";
import "react-grid-layout/css/styles.css"; // Import necessary CSS for grid
import "react-resizable/css/styles.css"; // Import necessary CSS for resizing
import { FaUser, FaPlus } from "react-icons/fa";
//import moment from "moment";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import Geofencing from "./components/Geofencing";
import FallDetection from "./components/FallDetection";
import WebSocketComponent from "./components/GeofencingWarning";

function App() {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/occupancy`;
  const apiUrlLive = `${process.env.REACT_APP_API_URL}/api/live-occupancy`;
  const apiUrlFall = `${process.env.REACT_APP_API_URL}/api/fall-detection`;

  // Declare state variables
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [occupancyData, setOccupancyData] = useState([]);
  const [isLiveMode, setIsLiveMode] = useState(false);
  const [liveData, setLiveData] = useState([]);
  const [fallData, setFallData] = useState([]);
  const [selectedFallData, setSelectedFallData] = useState([]);
  const [timeFrame, setTimeFrame] = useState([0, 1]);
  const [lineChartData, setLineChartData] = useState(null);
  const [averageOccupancy, setAverageOccupancy] = useState(null);
  const [averageRoomOccupancy, setRoomAverageOccupancy] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [calendarPlotData, setCalendarPlotData] = useState([]);
  const [pieChartData, setPieChartData] = useState(null);
  const [currentOccupancy, setCurrentOccupancy] = useState(null);
  const [currTotal, setCurrTotal] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  /* const [selectedRooms, setSelectedRooms] = useState([
    { value: "Room 1", label: "Room 1" },
  ]); */
  const [selectedRooms, setSelectedRooms] = useState(["Room 1"]);
  const [allRoomsOption] = useState("All Rooms");
  const [db, setDb] = useState(null); // IndexedDB setup for caching old data
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("dashboard");
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [alarmTriggered, setAlarmTriggered] = useState(false);
  const [layout, setLayout] = useState(null);
  const [layoutChanged, setLayoutChanged] = useState(false);
  const [savedLayout, setSavedLayout] = useState(layout);
  const [timeZone, setTimeZone] = useState("");
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [selectedPlotIds, setSelectedPlotIds] = useState(null);
  const [selectedfallRooms, setSelectedfallRooms] = useState(["Room 1"]);

  const defaultLayout = [
    { i: "lineChart", x: 0, y: 0, w: 8, h: 3, minW: 5, minH: 3, maxW: 12 }, // Line chart
    { i: "avgOccupancy", x: 4, y: 3, w: 2, h: 3, minW: 2, minH: 3 }, // Average occupancy
    { i: "calendarPlot", x: 8, y: 0, w: 4, h: 3, minW: 4, minH: 3 }, // Calendar plot
    { i: "pieChart", x: 8, y: 3, w: 4, h: 3, minW: 4, minH: 3 }, // Pie chart
    { i: "barChart", x: 0, y: 3, w: 4, h: 3, minW: 4, minH: 3 }, // Bar chart
    { i: "mapChart", x: 6, y: 3, w: 2, h: 3, minW: 2, minH: 3 }, // Map chart
    { i: "geofenceChart", x: 6, y: 0, w: 2, h: 3, minW: 2, minH: 3 }, // Geofence warning
    { i: "floorPlan", x: 4, y: 0, w: 2, h: 3, minW: 2, minH: 3 }, // Floor plan
  ];

  const plotOptions = [
    { id: "lineChart", label: "Line Chart" },
    { id: "pieChart", label: "Pie Chart" },
    { id: "barChart", label: "Bar Chart" },
    { id: "calendarPlot", label: "Calendar Plot" },
    { id: "mapChart", label: "Map Chart" },
    { id: "avgOccupancy", label: "Average Occupancy" },
    //{ id: "geofenceChart", label: "Geofence Warning" },
    //{ id: "floorPlan", label: "Floor Plan" }
  ];

  /* const [selectedPlots, setSelectedPlots] = useState(
   plotOptions.map(({ id, label }) => ({ value: id, label }))
 );  */

  //Help descriptions
  // Define the steps for the React Joyride tour
  const steps = [
    {
      target: ".line-chart-container", // class or ID of the element
      content:
        "This chart shows the total occupancy over the selected timeframe.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".average-occupancy-container",
      content:
        "This section shows the average occupancy for all rooms over the selected timeframe.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".calendar-container",
      content:
        "The calendar displays the occupancy pattern of the rooms over the month.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".pie-chart-container",
      content:
        "This chart visualizes the distribution of occupancy percentages.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".bar-chart-container",
      content: "This chart compares past and current occupancy per room.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".map-chart-container",
      content: "This chart shows the location of the sensor.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: ".controls",
      content:
        "The timeframe and rooms can be selected here. The live mode can be enabled to get updates every second for 2 minutes.",
      placement: "left",
      disableBeacon: true,
    },
    {
      target: ".save-layout-button-container",
      content:
        "Click here after plots are rearranged and resized to the preferred layout.",
      placement: "top",
      disableBeacon: true,
    },
  ];

  //COLORS
  //const color1 = `#${process.env.REACT_APP_COLOR1}`;
  //const color2 = `#${process.env.REACT_APP_COLOR2}`;
  const hexToRgba = (hex, alpha = 1) => {
    const [r, g, b] = hex
      .replace(/^#/, "")
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  /*   //Setting CSS variables
  document.documentElement.style.setProperty("--color1", color1);
  document.documentElement.style.setProperty("--color2", color2);
  document.documentElement.style.setProperty(
    "--color1-rgba",
    hexToRgba(color1, 0.5)
  ); // Example with transparency
  document.documentElement.style.setProperty(
    "--color2-rgba",
    hexToRgba(color2, 0.4)
  ); */
  async function getTimeZoneByIP() {
    try {
      // Fetch data from ipapi
      //const response = await fetch("https://ipapi.co/json/");
      const response = await fetch("https://ipwho.is/");
      const data = await response.json();
      // Extract the timezone from the API response
      //const timeZone = data.timezone.id;
      const timeZone = 'Europe/Berlin';
      return timeZone;
    } catch (error) {
      console.error("Error fetching timezone:", error);
    }
  }

  const handleLogin = (username, token, role, layout, color1, color2, geofence, selectedPlots) => {
    setUsername(username);
    setUserRole(role); // Store the user role
    setIsLoggedIn(true); // Set logged-in state
    setLayout(layout);
    setColor1(color1);
    setColor2(color2);
    setSelectedPlots(selectedPlots);
    const selectedPlotIds = selectedPlots.map((plot) => plot.value);
    setSelectedPlotIds(selectedPlotIds);

    //Setting CSS variables
    document.documentElement.style.setProperty("--color1", color1);
    document.documentElement.style.setProperty("--color2", color2);
    document.documentElement.style.setProperty(
      "--color1-rgba",
      hexToRgba(color1, 0.5)
    ); // Example with transparency
    document.documentElement.style.setProperty(
      "--color2-rgba",
      hexToRgba(color2, 0.4)
    );

    const fetchTimeZone = async () => {
      const timeZone = await getTimeZoneByIP();
      setTimeZone(timeZone);
    };

    fetchTimeZone();

    sessionStorage.setItem("authToken", token);
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("userRole", role);
    sessionStorage.setItem("layout", JSON.stringify(layout));
    sessionStorage.setItem("color1", color1);
    sessionStorage.setItem("color2", color2);
    sessionStorage.setItem("geofence", JSON.stringify(geofence));
    sessionStorage.setItem("selectedPlots", JSON.stringify(selectedPlots));
  };

  const handleLogout = () => {
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("layout");
    sessionStorage.removeItem("color1");
    sessionStorage.removeItem("color2");
    sessionStorage.removeItem("geofence");
    sessionStorage.removeItem("selectedPlots");
    setIsLoggedIn(false);
  };

  //Open Close the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle the sidebar state
  };

  //Handle plot changes
  const handleSavePlots = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LOGIN_URL}/api/auth/save-plots`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ selectedPlots }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save plot choices");
      }

      const result = await response.json();

      // Update saved plots and reset change tracker
      const updatedPlots = [...selectedPlots];
      alert("Plot choices saved successfully.");
    } catch (error) {
      console.error("Error saving plot choices:", error);
      alert("Failed to save plots. Please try again.");
    }
  }

  // Effect to load saved plots and plot selections from localStorage
  useEffect(() => {
    const savedPlots = JSON.parse(localStorage.getItem("selectedPlots"));
    if (savedPlots) {
      setSelectedPlots(savedPlots);
    }
  }, []);

  //Handle Layout changes
  const handleSaveLayout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LOGIN_URL}/api/auth/save-layout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({ layout }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save layout");
      }

      const result = await response.json();

      // Update saved layout and reset change tracker
      const updatedLayout = [...layout];
      setLayout(updatedLayout);
      setSavedLayout(updatedLayout);
      sessionStorage.setItem("layout", JSON.stringify(updatedLayout)); // Ensure session storage is updated
      alert("Layout saved successfully.");
      setLayoutChanged(false);
    } catch (error) {
      console.error("Error saving layout:", error);
      alert("Failed to save layout. Please try again.");
    }
  }

  // Effect to load saved layout and plot selections from localStorage
  useEffect(() => {
    const savedPlots = JSON.parse(localStorage.getItem("selectedPlots"));
    if (savedPlots) {
      setSelectedPlots(savedPlots);
    }
  }, []);


  const handlePlotSelect = (selectedOptions) => {
    const selectedPlotIds = selectedPlots.map((plot) => plot.value);
    setSelectedPlotIds(selectedPlotIds);
    const newLayout = selectedOptions.map((option) => {
      // Check if the layout already exists for the given plotId (i)
      const existingLayout = layout.find((item) => item.i === option.value);

      if (existingLayout) {
        // If layout exists, use it as is
        return existingLayout;
      } else {
        // If layout doesn't exist, use default layout
        return {
          i: option.value,
          x: 0,
          y: 0,
          w: 3,
          h: 3,
          minW: 3,
          minH: 3,
        };
      }
    });
    setLayout(newLayout); // Update the layout state
    setSelectedPlots(selectedOptions); // Update selected plots state
    sessionStorage.setItem("selectedPlots", JSON.stringify(selectedOptions));
  };

  // Handle navigation to the previous month
  const handlePrevMonth = () => {
    setMonth((prevMonth) => {
      if (prevMonth === 0) {
        setYear((prevYear) => prevYear - 1);  // Ensure year updates correctly
        return 11;
      }
      return prevMonth - 1;
    });
  };

  const handleNextMonth = () => {
    setMonth((prevMonth) => {
      if (prevMonth === 11) {
        setYear((prevYear) => prevYear + 1);  // Ensure year updates correctly
        return 0;
      }
      return prevMonth + 1;
    });
  };



  // Function to handle mouse down events for the buttons
  const handleMouseDown = (e) => {
    e.stopPropagation(); // Prevent drag from triggering
  };

  //Opening and closing help tags
  const handleHelpClick = () => {
    toggleSidebar();
    setIsTourRunning(true); // Start the tour
  };

  const handleTourEnd = () => {
    setIsTourRunning(false); // Reset after tour ends
  };

  //Live mode toggle
  const handleToggleLiveMode = (checked) => {
    setIsLiveMode(checked);
  };

  useEffect(() => {
    let timer;

    if (isLiveMode) {
      setTimeFrame([0, 15 / 3600]); // Default for live mode - change it to 15seconds LATER

      // Set a timer to turn off live mode after 60 minutes
      timer = setTimeout(() => {
        setIsLiveMode(false); // Turn off live mode
      }, 60 * 60 * 1000); // 60 minutes in milliseconds
    } else {
      setTimeFrame([0, 1]); // Default for non-live mode
    }

    // Cleanup the timer if the component unmounts or live mode changes
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isLiveMode]);

  let liveDataDB = new PouchDB("liveData");

  useEffect(() => {
    let fetchInterval;
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrlLive}?username=${sessionStorage.getItem("username")}`
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch live data");
        }
  
        const newDatalive = await response.json();
  
        // Filter data to only include entries where project === "CrowdEst"
        const filteredDataLive = newDatalive.filter(item => item.project === "crowd-estimation");
  
        // Add each item from the fetched data to PouchDB
        const dataWithTimestamps = filteredDataLive.map((item) => ({
          ...item,
          _id: `${item.room_id}-${item.timestamp}`, // Unique ID using room_id and timestamp
        }));
        await liveDataDB.bulkDocs(dataWithTimestamps); // Insert into PouchDB
  
        // Fetch and update liveData from PouchDB
        const allDocs = await liveDataDB.allDocs({ include_docs: true });
        if (isMounted){
        setLiveData(allDocs.rows.map((row) => row.doc)); // Update state with all docs
        }
      } catch (error) {
        console.error("Error fetching or storing live data:", error);
      }
    };
  
    const clearContent = async () => {
      try {
        // Fetch all docs and mark them for deletion
        const allDocs = await liveDataDB.allDocs({ include_docs: true });
        const docsToDelete = allDocs.rows.map((row) => ({
          ...row.doc,
          _deleted: true, // Mark for deletion
        }));
  
        // Delete the docs from the database
        if (docsToDelete.length > 0) {
          await liveDataDB.bulkDocs(docsToDelete);
        }

        // Clear the state after deletion if the component is still mounted
      if (isMounted) {
        setLiveData([]);
      }
      } catch (error) {
        console.error("Error clearing content in PouchDB:", error);
      }
    };
  
    if (isLiveMode) {
      // Fetch data every second and store in PouchDB
      fetchInterval = setInterval(fetchData, 1000);
    } else {
      // Clear content when live mode is turned off
      clearContent(); // Perform content deletion
    }
  
    // Cleanup on component unmount or mode change
    return () => {
      isMounted = false; // Mark component as unmounted
      if (fetchInterval) clearInterval(fetchInterval);
      //if (liveDataDB) liveDataDB.close(); // Ensure the database is properly closed
    };
  }, [isLiveMode, apiUrlLive]);
  
  

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => setAvailableHeight(window.innerHeight);
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // Initialize PouchDB and load old data from cache
  useEffect(() => {
    const initializeAndFetchData = async () => {
      const localDb = new PouchDB("occupancyData");
      setDb(localDb);

      // Optionally, sync with a remote database if required
      // localDb.sync('http://remote-db-url.com');

      // Fetch cached data from PouchDB
      try {
        const cachedData = await localDb.allDocs({ include_docs: true });
        const data = cachedData.rows.map((row) => row.doc);
        setOccupancyData(data);
      } catch (error) {
        console.error("Error fetching cached data from PouchDB:", error);
      }
    };

    initializeAndFetchData();
    // Set an interval to check for old data every day
    const intervalId = setInterval(deleteOldData, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const saveDataToPouchDB = async (data) => {
    if (!db) return;

    try {
      for (const item of data) {
        const docId = `${item.timestamp}_${item.room_id}`; // Unique Key

        try {
          const existingDoc = await db.get(docId);

          // Check if the data is actually different before updating
          if (
            existingDoc.occupancy !== item.occupancy // Only update if occupancy changed
          ) {
            await db.put({
              ...existingDoc,
              ...item,
              _rev: existingDoc._rev, // Maintain correct revision
            });
          }
        } catch (err) {
          if (err.name === "not_found") {
            // New document, insert fresh
            await db.put({ _id: docId, ...item });
          } else {
            throw err; // Unexpected error
          }
        }
      }
    } catch (error) {
      console.error("Error saving data to PouchDB:", error);
    }
  };

  const getCachedDataFromPouchDB = async () => {
    if (!db) return [];
    try {
      const cachedData = await db.allDocs({ include_docs: true });
      return cachedData.rows.map((row) => row.doc);
    } catch (error) {
      console.error("Error fetching cached data from PouchDB:", error);
      return [];
    }
  };

  // Delete data older than a month based on the timestamp
  const deleteOldData = async () => {
    if (!db) return;

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30); // Subtract 30 days
    const thirtyDaysAgoTimestamp = thirtyDaysAgo.getTime();

    try {
      const cachedData = await db.allDocs({ include_docs: true });
      const docsToDelete = cachedData.rows.filter(
        (row) => row.doc.timestamp < thirtyDaysAgoTimestamp
      );

      for (const doc of docsToDelete) {
        await db.remove(doc.doc); // Delete the document
      }
    } catch (error) {
      console.error("Error deleting old data from PouchDB:", error);
    }
  };

  let fallDataDB = new PouchDB("fallData");

  useEffect(() => {
    let fetchInterval;

    // Fetch data every second and store in PouchDB
    fetchInterval = setInterval(async () => {
      try {
        console.log(`${apiUrlFall}?username=${sessionStorage.getItem("username")}`);
        const response = await fetch(`${apiUrlFall}?username=${sessionStorage.getItem("username")}`);
        if (!response.ok) {
          throw new Error("Failed to fetch live data");
        }

        const newDatafall = await response.json();

        // Filter data to only include entries where project === "CrowdEst"
        const filteredDatafall = newDatafall.filter(item => item.project === "fall-detection");

        // Add each item from the fetched data to PouchDB
        const falldataWithTimestamps = filteredDatafall.map((item) => ({
          ...item,
          _id: `${item.room_id}-${item.timestamp}`, // Unique ID using room_id and timestamp
        }));

        try {
          if (falldataWithTimestamps.length > 0) {
            const result = await fallDataDB.bulkDocs(falldataWithTimestamps);
          }
        } catch (error) {
          console.error("Error inserting into PouchDB:", error);
        }

        // Fetch and update liveData from PouchDB
        const allDocs = await fallDataDB.allDocs({ include_docs: true });
        // Now we need to convert timestamp to Date and check against Date.now()
        const docsToDelete = allDocs.rows
          .filter((row) => {
            const docTimestamp = new Date(row.doc.timestamp); // Convert timestamp string to Date object
            return Date.now() - docTimestamp.getTime() > 15000; // 15 seconds in milliseconds
          })
          .map((row) => ({
            ...row.doc,
            _deleted: true, // Mark for deletion
          }));

        if (docsToDelete.length > 0) {
          await fallDataDB.bulkDocs(docsToDelete); // Delete old records
        }

        setFallData(allDocs.rows.map((row) => row.doc)); // Update state with all docs
      } catch (error) {
        console.error("Error fetching or storing live data:", error);
      }
    }, 1000);
    // Cleanup on component unmount or mode change
    return () => {
      if (fetchInterval) clearInterval(fetchInterval);
    };
  }, [apiUrlFall]);

  const handlefallRoomChange = (selectedOptions) => {
    setSelectedfallRooms(selectedOptions.value);
    const fallRoomData =
      selectedfallRooms.length === 0
        ? fallData
        : fallData.filter((entry) => selectedfallRooms.includes(entry.room_id));
    setSelectedFallData(fallRoomData);
  };

  useEffect(() => {
    const fallRoomData =
      selectedfallRooms.length === 0
        ? fallData
        : fallData.filter((entry) => selectedfallRooms.includes(entry.room_id));
    setSelectedFallData(fallRoomData);
  }, [fallData, selectedfallRooms]);


  //Handling login-logout
  useEffect(() => {
    // Check session storage for user data
    const token = sessionStorage.getItem("authToken");
    const savedUsername = sessionStorage.getItem("username");
    //const savedRole = sessionStorage.getItem("userRole");
    const savedLayout = sessionStorage.getItem("layout");
    const savedColor1 = sessionStorage.getItem("color1");
    const savedColor2 = sessionStorage.getItem("color2");
    const savedselectedPlots = JSON.parse(sessionStorage.getItem("selectedPlots"));

    if (token) {
      setIsLoggedIn(true);
      setUsername(savedUsername);
      //setUserRole(savedRole);
      setLayout(JSON.parse(savedLayout));
      setColor1(savedColor1);
      setColor2(savedColor2);
      setSelectedPlots(savedselectedPlots);
      const selectedPlotIds = savedselectedPlots.map((plot) => plot.value);
      setSelectedPlotIds(selectedPlotIds);

      //Setting CSS variables
      document.documentElement.style.setProperty("--color1", savedColor1);
      document.documentElement.style.setProperty("--color2", savedColor2);
      document.documentElement.style.setProperty(
        "--color1-rgba",
        hexToRgba(savedColor1, 0.5)
      ); // Example with transparency
      document.documentElement.style.setProperty(
        "--color2-rgba",
        hexToRgba(savedColor2, 0.4)
      );
      const fetchTimeZone = async () => {
        const timeZone = await getTimeZoneByIP();
        setTimeZone(timeZone);
      };

      fetchTimeZone();
    }
  }, []);

  //Setting timezone
  useEffect(() => {
    const fetchTimeZone = async () => {
      const timeZone = await getTimeZoneByIP();
      setTimeZone(timeZone);
    };

    fetchTimeZone();
  }, []);

  //Handling layout reassigning on change to the selected plots
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    const savedLayout = sessionStorage.getItem("layout");
    if (token) {
      setLayout(JSON.parse(savedLayout));
    }
  }, [selectedPlots]);

  //Prepare the data on changes
  useEffect(() => {
    const fetchOccupancyData = async () => {
      try {
        if (!db) {
          console.warn("DB not initialized");
          return;
        }
        //Retrieve cached data
        const cachedData = await getCachedDataFromPouchDB();

        //Last timestamp
        let lastTimestamp; // Declare outside to be accessible globally

        if (cachedData.length > 0) {
          lastTimestamp = Math.max(
            ...cachedData.map((item) => {
              const dt = DateTime.fromFormat(
                item.timestamp,
                "yyyy-MM-dd HH:mm:ss",
                { zone: timeZone }
              );

              return dt.toMillis();
            })
          );
        } else {
          // If no cached data, fetch for the last 30 days
          lastTimestamp = DateTime.now().setZone(timeZone).minus({ days: 30 });
          lastTimestamp = lastTimestamp.toMillis();

          // TEMPORARY: Override to fetch for last 1 hour
          // lastTimestamp = DateTime.now().minus({ hours: 1 }).toMillis();
        }


        // Fetch new data from the last cached timestamp to the previous 10-minute mark
        const now = moment
          .tz(new Date(), timeZone)
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        /* const tenMinuteMark = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          now.getHours(),
          Math.floor(now.getMinutes() / 10) * 10,
          0
        ); */
        lastTimestamp = DateTime.fromMillis(lastTimestamp)
          .setZone(timeZone)
          .toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        // Change the timezone but keep the same local time
        /* lastTimestamp = DateTime.fromISO(lastTimestamp)
          .setZone(timeZone, { keepLocalTime: true })
          .toISO();
        console.log("lastTimestamp4", lastTimestamp); */
        const apiUrlWithParams = `${apiUrl}?startDate=${lastTimestamp}&endDate=${now}&username=${sessionStorage.getItem(
          "username"
        )}`;
        //).toISOString()}&endDate=${tenMinuteMark.toISOString()}`;
        console.log("apiUrlWithParams", apiUrlWithParams);
        const response = await fetch(apiUrlWithParams);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const newData = await response.json();

        const filteredNewData = newData.filter(item => item.project === "crowd-estimation");

        // Merge and update cache
        const consolidatedData = [...cachedData, ...filteredNewData];
        setOccupancyData(consolidatedData);
        await saveDataToPouchDB(filteredNewData); // Save new data to PouchDB
        deleteOldData();
      } catch (error) {
        console.error("Error fetching occupancy data:", error);
      }
    };

    const getNextIntervalDelay = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();
      const currentMillisecond = now.getMilliseconds();

      //Wait until the next 10-minute mark
      const nextReloadInMinutes = 10 - (currentMinute % 10);
      const delayToNext10MinuteMark =
        nextReloadInMinutes * 60 * 1000 -
        currentSecond * 1000 -
        currentMillisecond;
      return delayToNext10MinuteMark;
    };

    let timeoutId;

    const scheduleFetch = async () => {
      await fetchOccupancyData();
      const delay = getNextIntervalDelay();
      timeoutId = setTimeout(scheduleFetch, delay);
    };

    if (timeZone) {
      scheduleFetch();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [db, timeZone]);

  const consolidateToGivenPoints = (data, size) => {
    // Format the data into a consistent structure
    const items = data.map((item) => ({
      timestamp: moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss"),
      room_id: item.room_id,
      occupancy: item.occupancy,
    }));

    // Determine the grouping interval to get exactly given number of data points
    const interval = Math.ceil(items.length / size); // Group size

    // Consolidate data into 20 groups
    const groupedData = {};
    items.forEach((item, index) => {
      // Calculate group index based on interval
      const groupIndex = Math.floor(index / interval);

      // Group data by room and groupIndex
      const roomKey = `${groupIndex}-${item.room_id}`;

      if (!groupedData[roomKey]) {
        groupedData[roomKey] = {
          timestamp: moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss"),
          room_id: item.room_id,
          totalOccupancy: 0,
          dataPoints: 0,
        };
      }

      groupedData[roomKey].totalOccupancy += item.occupancy;
      groupedData[roomKey].dataPoints += 1;
    });

    // Convert grouped data to an array and calculate averages
    const aggregatedData = Object.keys(groupedData).map((key) => {
      const { timestamp, room_id, totalOccupancy, dataPoints } =
        groupedData[key];
      return {
        timestamp, // Use the first timestamp in the group
        room_id,
        occupancy: (totalOccupancy / dataPoints).toFixed(0), // Average occupancy
      };
    });

    return aggregatedData;
  };

  useEffect(() => {
    // Always calculate `calendarPlotData` from occupancyData, regardless of live mode
    const selectedRoomValues = selectedRooms.map((room) => room.value);
    const calendarData =
      selectedRooms.includes(allRoomsOption) ||
        selectedRooms.length === 0
        ? occupancyData
        : occupancyData.filter((entry) =>
          selectedRooms.includes(entry.room_id)
        );
    setCalendarPlotData(calendarData);
  }, [
    occupancyData,
    db,
    selectedRooms,
    timeFrame,
    isLiveMode,
    liveData,
    layout,
    timeZone,
    selectedPlots,
  ]);

  // Update data when occupancyData or timeFrame changes
  useEffect(() => {
    if (occupancyData.length > 0) {

      const fetchFilteredData = async () => {
        try {
          const dataToProcess = isLiveMode ? liveData : occupancyData;

          // Filter data based on selected timeframe
          //const filteredData = filterDataByTimeframe(dataToProcess, timeFrame);
          const filteredData = await filterDataByTimeframe(
            dataToProcess,
            timeFrame[0],
            timeFrame[1],
            timeZone
          );

          //Consolidation parameters
          const lineChartLayout = layout.find((item) => item.i === "lineChart");
          const pointsToDisplay = Math.max(
            Math.floor(lineChartLayout.w * 10),
            20
          ); // Each `w` unit represents 10 data points, minimum 20 points
          const consolidatedData =
            !isLiveMode && filteredData.length > pointsToDisplay
              ? consolidateToGivenPoints(filteredData, pointsToDisplay)
              : filteredData; // Use all data if less than pointsToDisplay

          //Filter data by room and timeframe
          //const selectedRoomValues = selectedRooms.map((room) => room.value);
          const roomFilteredData =
            selectedRooms.includes(allRoomsOption) ||
              selectedRooms.length === 0
              ? consolidatedData
              : consolidatedData.filter((entry) =>
                selectedRooms.includes(entry.room_id)
              );
          const average = CalcAverageOccupancy(roomFilteredData);
          setAverageOccupancy(average); // Set average occupancy

          const roomAvg = CalcRoomAvgOccupancy(roomFilteredData);
          setRoomAverageOccupancy(roomAvg); // Set room-wise average occupancy

          // Prepare data for line chart
          // Prepare data for line chart, ensuring unique timestamps and room_ids are added
          const occupancyByTimestamp = roomFilteredData.reduce((acc, entry) => {
            const timestampKey = new Date(entry.timestamp).toISOString();
            const roomId = entry.room_id;

            // Initialize if not already initialized for this timestamp
            if (!acc[timestampKey]) {
              acc[timestampKey] = {};
            }

            // If the room_id hasn't been encountered for this timestamp, add its occupancy
            if (!acc[timestampKey][roomId]) {
              acc[timestampKey][roomId] = Number(entry.occupancy);
            }

            // We avoid adding multiple times by checking if the room_id is already counted for this timestamp
            return acc;
          }, {});

          // Now, to prepare the chart data:
          const sortedTimestamps = Object.keys(occupancyByTimestamp).sort();

          /* const labels = sortedTimestamps.map((timestamp) => {
          return format(new Date(timestamp), "HH:mm:ss"); // Format as needed
        }); */

          const labels = sortedTimestamps.map((timestamp) => {
            // Check if the timeframe is '7d' or '14d'
            //if (timeFrame === "7d" || timeFrame === "14d") {
            if (timeFrame[0] > 48 || timeFrame[1] > 48) {
              return format(new Date(timestamp), "MM/dd/yyyy"); // Format as 'MM/dd/yyyy'
            }
            return format(new Date(timestamp), "HH:mm:ss"); // Default format
          });

          // Calculate total occupancy for each timestamp across all rooms
          const dataPoints = sortedTimestamps.map((timestamp) => {
            const totalOccupancy = Object.values(
              occupancyByTimestamp[timestamp]
            ).reduce((sum, occupancy) => sum + occupancy, 0);
            return totalOccupancy;
          });

          setLineChartData({
            labels: labels,
            datasets: [
              {
                label: "Total Occupancy",
                data: dataPoints,
                //borderColor: "rgba(159, 41, 42,255)", // Set color
                //backgroundColor: "rgba(159, 41, 42,0.2)", // Set transparency
                borderColor: color2, // Set color
                backgroundColor: hexToRgba(color2, 0.2), // Set transparency
                fill: true,
              },
            ],
          });

          // Prepare data for pie chart
          const countMap = {};

          // Iterate over the occupancy values (not timestamp values) in occupancyByTimestamp
          for (const timestampKey of Object.keys(occupancyByTimestamp)) {
            const roomsOccupancy = Object.values(
              occupancyByTimestamp[timestampKey]
            );
            for (const occupancy of roomsOccupancy) {
              // Count the occurrences of each occupancy value
              countMap[occupancy] = (countMap[occupancy] || 0) + 1;
            }
          }

          const totalCount = Object.values(countMap).reduce(
            (sum, count) => sum + count,
            0
          ); // Total count of all occupancy occurrences
          const percentageShare = {};

          // Calculate percentage share for each occupancy value
          for (const [occupancy, count] of Object.entries(countMap)) {
            percentageShare[occupancy] = ((count / totalCount) * 100).toFixed(
              2
            );
          }

          setPieChartData({
            labels: Object.keys(percentageShare),
            datasets: [
              {
                label: "Occupancy distribution",
                data: Object.values(percentageShare),
              },
            ],
          });

          // Prepare data for bar chart

          //get current occupancy
          const nRooms = new Set(roomFilteredData.map((entry) => entry.room_id))
            .size; //Extract unique room IDs
          //Compute current occupancy directly
          const currentOccupancy = roomFilteredData
            .slice(-nRooms)
            .map((entry) => entry.occupancy);

          const currTotal = currentOccupancy.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
          const currTime = new Date();
          const currHour = currTime.getHours();
          if (currTotal > 0 && (currHour > 18 || currHour < 7)) {
            //alert("Occupant detected!");
          }
          setCurrTotal(currTotal);
          setBarChartData({
            labels: Object.keys(roomAvg),
            datasets: [
              {
                label: "Past Occupancy",
                data: Object.values(roomAvg),
                //borderColor: "rgba(159, 41, 42,1)",
                //backgroundColor: "rgba(159, 41, 42,0.8)",
                borderColor: color2, // Set color
                backgroundColor: hexToRgba(color2, 0.8), // Set transparency
              },
              {
                label: "Current Occupancy",
                data: currentOccupancy, //  current occupancy data
                //borderColor: "rgba(159, 41, 42,1)",
                //backgroundColor: "rgba(159, 41, 42,0.4)",
                borderColor: color2, // Set color
                backgroundColor: hexToRgba(color2, 0.4), // Set transparency
              },
            ],
          });
          setCurrentOccupancy(currentOccupancy);
        } catch (error) {
          console.error("Error processing data:", error); // Handle errors in data processing
        }
      };
      fetchFilteredData();
    }
  }, [
    occupancyData,
    db,
    selectedRooms,
    timeFrame,
    isLiveMode,
    liveData,
    layout,
    timeZone,
    selectedPlots,
  ]); // Trigger when occupancyData or timeFrame changes

  // Room options
  const roomOptions = [
    //{ value: "All Rooms", label: "All Rooms" },
    { value: "Room 1", label: "Room 1" },
    { value: "Room 2", label: "Room 2" },
    /*     { value: "Room 3", label: "Room 3" },
        { value: "Room 4", label: "Room 4" },
        { value: "Room 5", label: "Room 5" },
        { value: "Room 6", label: "Room 6" },
        { value: "Room 7", label: "Room 7" }, */
  ];

  const handleRoomChange = (selectedOptions) => {
    //const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    if (selectedOptions.includes("All Rooms") && selectedOptions.length > 1) {
      // If "All Rooms" is selected, clear other selections
      setSelectedRooms({ value: "All Rooms", label: "All Rooms" });
    } else if (!selectedOptions.includes("All Rooms")) {
      // If specific rooms are selected, keep them and ensure "All Rooms" is not selected
      setSelectedRooms(selectedOptions);
    } else {
      // Edge case: If "All Rooms" is selected alone
      setSelectedRooms([{ value: "All Rooms", label: "All Rooms" }]);
    }
  };


  //Update selected rooms from floorplan
  const handleRoomSelection = (rooms) => {
    //setSelectedFloorRooms(rooms);
    setSelectedRooms(rooms);
  };

  // Handle slider change for timeframe
  const handleSliderChange = (hours) => {
    // Call the function to update the selected timeframe (in hours or days)
    handleTimeFrameChange(hours);
  };
  const handleLiveSliderChange = (seconds) => {
    // Call the function to update the selected timeframe (in seconds)
    handleTimeFrameChange([0, seconds / 3600]);
  };

  const CustomOption = (props) => {
    const { data, isSelected } = props;
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          //checked={isSelected}
          checked={props.isSelected}
          onChange={() => null} // Checkbox handled by React-Select
        //style={{ marginRight: "8px" }}
        />
        {data.label}
      </components.Option>
    );
  };

  //Timeframe dropdown menu
  const timeFrameOptions = isLiveMode
    ? [
      { value: "15s", label: "Last 15 seconds" },
      { value: "30s", label: "Last 30 seconds" },
      { value: "1m", label: "Last 1 minute" },
      { value: "2m", label: "Last 2 minutes" },
    ]
    : [
      { value: "1m", label: "Last 1 minute" },
      { value: "2m", label: "Last 2 minutes" },
      { value: "30m", label: "Last 30 minutes" },
      { value: "1h", label: "Last 1 hour" },
      { value: "3h", label: "Last 3 hours" },
      { value: "6h", label: "Last 6 hours" },
      { value: "12h", label: "Last 12 hours" },
      { value: "18h", label: "Last 18 hours" },
      { value: "24h", label: "Last 24 hours" },
      { value: "48h", label: "Last 48 hours" },
      { value: "7d", label: "Last 7 days" },
      { value: "14d", label: "Last 14 days" },
    ];

  const handleTimeFrameChange = (selectedOption) => {
    //setTimeFrame(selectedOption.value); // Update the selected timeframe
    setTimeFrame(selectedOption);
  };

  //Sidebar dynamic sizing
  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  ); // Get root font size in pixels
  const sidebarWidth = 15 * rootFontSize;



  return (
    <Router>
      <div className="App">
        {/* Header section with logo */}
        <div className="header">
          <img src="/logo.png" alt="Logo" className="logo" />{" "}
          {/* Ensure the correct path to the logo */}
        </div>

        {/* Hamburger Icon for Sidebar Toggle */}
        {isLoggedIn ? (
          <button className="hamburger-icon" onClick={toggleSidebar}>
            ☰
          </button>
        ) : null}

        {/* Sidebar for navigation */}
        {isLoggedIn && isSidebarOpen && (
          <Sidebar
            username={username}
            userRole={userRole}
            onLogout={handleLogout}
            onHelp={handleHelpClick}
            setActiveSection={setActiveSection}
            activeSection={activeSection}
            color1={color1}
          />
        )}

        {/*Routes*/}
        <Routes>
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/*Main App section*/}
          {/*Render Login component*/}
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <>
                  {activeSection === "dashboard" && (
                    <>
                      <Joyride
                        steps={steps}
                        run={isTourRunning}
                        continuous
                        showSkipButton
                        //showProgress
                        styles={{
                          options: {
                            arrowColor: color1,
                            primaryColor: color1,
                            zIndex: 1000,
                          },
                        }}
                        callback={(data) => {
                          const { status } = data;
                          if (["finished", "skipped"].includes(status)) {
                            handleTourEnd(); // Stop the tour when finished or skipped
                          }
                        }}
                      />
                      <h1>Occupancy Dashboard</h1> {/* Main dashboard title */}
                      {/* Controls for selecting timeframe */}
                      <div className="controls">
                        {/* Live Mode Toggle */}
                        <label htmlFor="liveMode">Live Mode:</label>
                        <Switch
                          onChange={handleToggleLiveMode}
                          checked={isLiveMode}
                          onColor={color1}
                          offColor="#cccccc"
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                        {/* <label htmlFor="timeframe">Select Time Frame:</label>
                      <ReactSelect
                        id="timeframe"
                        options={timeFrameOptions} // Timeframe options
                        value={timeFrameOptions.find(
                          (option) => option.value === timeFrame
                        )} // Match selected value
                        onChange={handleTimeFrameChange} // Handle change
                        placeholder="Select Time Frame..."
                        closeMenuOnSelect={true} // Close menu after single select
                        hideSelectedOptions={false}
                      /> */}

                        {/* Replace Timeframe Dropdown with Slider */}
                        <label htmlFor="timeframe">Select Time Frame:</label>
                        {isLiveMode && timeZone ? (
                          <LiveModeSlider
                            onIntervalChange={handleLiveSliderChange}
                            timeZone={timeZone}
                          />
                        ) : timeZone ? (
                          <TimeframeSlider
                            onTimeframeChange={handleSliderChange}
                            timeZone={timeZone}
                          />
                        ) : null}

                        {/* <label htmlFor="roomSelect">Select Rooms:</label>
                        <ReactSelect
                          id="roomSelect"
                          isMulti // Enable multi-select
                          options={roomOptions} // Room options
                          value={selectedRooms} // Selected options
                          onChange={handleRoomChange} // Handle changes
                          placeholder="Select Rooms"
                          closeMenuOnSelect={false} // Keep menu open for multi-select
                          hideSelectedOptions={false}
                          //className="room-selector" // Optional styling class
                          components={{
                            Option: CustomOption,
                            MultiValue: () => ""
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "150px", // Adjust dropdown width
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "150px", // Ensures dropdown menu width matches the control
                            }),
                          }}
                        /> */}

                        {/* Plot Selection Menu */}
                        <label htmlFor="plotSelect">Select Plots:</label>
                        <ReactSelect
                          id="plotSelect"
                          isMulti // Allow multi-selection
                          options={plotOptions.map(({ id, label }) => ({
                            value: id,
                            label: label,
                          }))}
                          value={selectedPlots}
                          onChange={handlePlotSelect} // Handle the plot selection change
                          closeMenuOnSelect={false} // Keep the dropdown open after selecting
                          hideSelectedOptions={false}
                          placeholder="Select Plots"
                          formatOptionLabel={() => ""}
                          components={{
                            Option: CustomOption,
                            MultiValue: () => ""
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "150px", // Adjust dropdown width
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: "150px", // Ensures dropdown menu width matches the control
                            }),
                          }}
                        />
                      </div>
                      {/* Dashboard grid layout */}
                      <GridLayout
                        className={`layout grid-layout ${isSidebarOpen ? "with-sidebar" : ""
                          }`}
                        layout={layout}
                        //layout={defaultLayout}
                        cols={12}
                        rowHeight={availableHeight / 14}
                        //rowHeight={90}
                        //width={window.innerWidth}
                        width={
                          window.innerWidth - (isSidebarOpen ? sidebarWidth : 0)
                        }
                        margin={[40, 40]}
                        isResizable={true}
                        resizeHandles={["se", "sw", "ne", "nw"]}
                        isDraggable={true}
                        compactType="vertical"
                        preventCollision={false} // Prevent overlapping components
                        onLayoutChange={(newLayout) => {
                          setLayout(newLayout);
                          setLayoutChanged(
                            JSON.stringify(newLayout) !==
                            JSON.stringify(savedLayout)
                          ); // Compare to detect changes
                        }}
                      >
                        {/*Floor Plan*/}
                        <div
                          key="floorplan"
                          className="floorplan-container"
                          style={{
                            marginTop: 0,
                          }}
                        >
                          <Floorplan
                            onRoomSelectionChange={handleRoomSelection} color1={color1} color2={color2}
                          />
                          
                        </div>
                        {/* Line chart */}
                        {selectedPlotIds.includes("lineChart") && (
                          <div key="lineChart" className="line-chart-container">
                            {lineChartData && (
                              <LineChart
                                data={lineChartData}
                                time={timeFrame}
                                color1={color2}
                              />
                            )}
                          </div>)}

                        {/* Average occupancy section */}
                        {selectedPlotIds.includes("avgOccupancy") && isLiveMode ? (
                          // Live Mode Section
                          <div
                            key="avgOccupancy"
                            className="live-mode-container"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
                              Current Occupancy{" "}
                            </h2>
                            {currentOccupancy ? (
                              <h1
                                style={{
                                  fontSize: "80px",
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                }}
                              >
                                <span style={{ color: color1 }}>
                                  {/*{currentOccupancy}*/}
                                  {currentOccupancy.reduce((sum, item) => sum + item, 0)}
                                </span>
                                <FaUser
                                  style={{ fontSize: "48px", color: color1 }}
                                />
                              </h1>
                            ) : null}
                          </div>
                        ) : selectedPlotIds.includes("avgOccupancy") ? (
                          <div
                            key="avgOccupancy"
                            className="average-occupancy-container"
                          >
                            <h2>Average Occupancy</h2>
                            <h3>
                              <span style={{ color: color1 }}>
                                {averageOccupancy}
                              </span>{" "}
                              people
                            </h3>
                            {averageRoomOccupancy &&
                              Object.entries(averageRoomOccupancy).length > 0 ? (
                              <table>
                                <thead>
                                  <tr>
                                    <th>Room ID</th>
                                    <th>Average Occupancy</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(averageRoomOccupancy).map(
                                    ([roomId, average]) => (
                                      <tr key={roomId}>
                                        <td>{roomId}</td>
                                        <td>{average}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <p>No data available</p> // Display if no room data is available
                            )}
                          </div>
                        ) : null}

                        {/* Calendar plot */}
                        {selectedPlotIds.includes("calendarPlot") && (
                          <div key="calendarPlot" className="calendar-container">
                            <h2>
                              Average Occupancy Calendar for {month + 1}/{year}
                            </h2>
                            <div className="month-navigation">
                              <button
                                onMouseDown={handleMouseDown}
                                onClick={handlePrevMonth}
                              >
                                Previous Month
                              </button>
                              <button
                                onMouseDown={handleMouseDown}
                                onClick={handleNextMonth}
                              >
                                Next Month
                              </button>
                            </div>
                            <CalendarPlot
                              year={year}
                              month={month}
                              data={calendarPlotData}
                              color1={color2}
                            />
                          </div>)}

                        {/* Pie chart */}
                        {selectedPlotIds.includes("pieChart") && (
                          <div key="pieChart" className="pie-chart-container">
                            {pieChartData && (
                              <PieChart
                                data={pieChartData}
                                time={timeFrame}
                                color1={color2}
                              />
                            )}
                          </div>)}

                        {/* Bar chart */}
                        {selectedPlotIds.includes("barChart") && (
                          <div key="barChart" className="bar-chart-container">
                            {barChartData && (
                              <BarChart
                                data={barChartData}
                                time={timeFrame}
                                color1={color2}
                              />
                            )}
                          </div>)}

                        {/* Map chart */}
                        {selectedPlotIds.includes("mapChart") && (
                          <div key="mapChart" className="map-chart-container">
                            <MapComponent
                              //lat={51.10199797044803}
                              //lon={6.888366311367101}
                              color1={color2}
                            />
                          </div>)}

                        {selectedPlotIds.includes("geofenceChart") && (
                          <div key="geofenceChart" className="geofence-chart-container">
                            <WebSocketComponent username={username} color1={color1} />
                          </div>)}

                        {/* Warning Message when occupancy exceeds 2 */}
                        {currTotal > 2 && (
                          <div className="warning-message">
                            <span
                              role="img"
                              aria-label="error"
                              className="error-icon"
                            >
                              ⚠️
                            </span>
                            <p>Occupancy Count is greater than 2!</p>
                          </div>
                        )}
                      </GridLayout>
                      <div className="button-container">
                        <div className="save-layout-button-container">
                          <button
                            onClick={handleSaveLayout}
                            disabled={!layoutChanged} // Disable button if no changes
                            className={`save-layout-button ${layoutChanged ? "" : "disabled"}`}
                          >
                            Save Layout
                          </button>
                        </div>
                        <div className="save-plots-button-container">
                          <button onClick={handleSavePlots} className="save-plots-button">
                            Save Plots
                          </button>
                        </div>
                      </div>


                    </>
                  )}
                  {isLoggedIn && activeSection === "geofencing" && (
                    <>
                      <h1> Set Geofence Boundary</h1>
                      <div style={{ height: "100vh" }}>
                        <div className="geofencing-container" style={{
                          marginLeft:
                            (isSidebarOpen ? 80 + sidebarWidth : 80),
                          width: window.innerWidth - (isSidebarOpen ? sidebarWidth : 280)
                        }}>
                          <Geofencing username={username} />
                        </div>
                      </div>
                    </>
                  )}
                  {activeSection === "fallDetection" && (
                    <>
                      <h1>Fall Detection</h1>
                      <div className="controls">
                        <label htmlFor="roomSelect">Select Rooms:</label>
                        <ReactSelect
                          id="roomSelect"
                          options={roomOptions} // Room options
                          //value={selectedRooms} // Selected options
                          value={roomOptions.find((option) => option.value === selectedRooms)}
                          onChange={handlefallRoomChange} // Handle changes
                          placeholder="Select Rooms..."
                          closeMenuOnSelect={true} // Keep menu open for multi-select
                          hideSelectedOptions={false}
                        />
                      </div>
                      <div style={{ height: "100vh" }}>
                        <div className="fall-detection-container" style={{
                          marginLeft:
                            (isSidebarOpen ? 80 + sidebarWidth : 80),
                          width: window.innerWidth - (isSidebarOpen ? sidebarWidth : 280)
                        }}>
                          <FallDetection fallData={selectedFallData} color2={color2} />
                        </div>
                      </div>
                    </>
                  )}
                </>

              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
