import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [status, setStatus] = useState("Verifying...");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");

      if (!token) {
        setStatus("No token provided.");
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/api/auth/verify-email?token=${token}`);
        if (response.ok) {
          setStatus("Email successfully verified!");
          setTimeout(() => navigate("/"), 2000); // Redirect after 2 seconds
        } else {
          const errorMessage = await response.text();
          setStatus(errorMessage || "Invalid or expired verification link.");
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        setStatus("An error occurred while verifying your email. Please try again later.");
      }
    };

    verifyEmail();
  }, [location.search, navigate]);

  return (
    <div style={{ textAlign: "center", padding: "50px" , height: "100vh"}}>
      <h1>Email Verification</h1>
      <p>{status}</p>
    </div>
  );
};

export default VerifyEmail;
