import { useEffect, useState } from "react";

const WebSocketComponent = ({ username, color1 }) => {

    const [socket, setSocket] = useState(null);
    const [warnings, setWarnings] = useState(null);

    useEffect(() => {
        if (!username) return; // Don't connect if user is not logged in

        const protocol = window.location.protocol === "https:" ? "wss" : "ws";
        const ws = new WebSocket(`${protocol}://server-tmo.eba-pwuk8xub.us-west-2.elasticbeanstalk.com?username=${username}`);

        //const ws = new WebSocket(`ws://server-tmo.eba-pwuk8xub.us-west-2.elasticbeanstalk.com?username=${username}`);
        console.log("Ws", ws);

        ws.onopen = () => {
            console.log("Connected to WebSocket");
        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === "GEOFENCE_WARNING") {
                console.warn("Geofence Warning:", message);
                setWarnings(message);
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket Error:", error);
        };

        ws.onclose = () => {
            console.log("WebSocket closed. Reconnecting...");
            setTimeout(() => setSocket(new WebSocket(ws.url)), 5000); // Auto-reconnect
        };

        setSocket(ws);

        return () => ws.close(); // Cleanup on unmount
    }, [username]);

    return (
        <div>
            <h3>Geofence Warnings</h3>

            {warnings ? (
                <h2 style={{ color: color1}}>
                    {warnings.message} (Room: {warnings.room_id}, Time: {new Date(warnings.timestamp).toLocaleTimeString()})
                </h2>
            ) : (<p>No warnings</p>
            )}
        </div>
    );
};

export default WebSocketComponent;
