import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

/**
 * BarPlot component renders a bar chart using the provided data.
 * If the data is invalid or unavailable, it displays a message indicating no data.
 * 
 * @param {Object} data - Data object containing labels and datasets for the bar chart.
 * @param {string} time - Timeframe for the data (used in the title).
 *  @param {string} color1 - Colors for the chart
 */
const BarPlot = ({ data, time, color1 }) => {
  // Error handling: Check if the data is present and has valid labels and datasets
  if (!data || !data.labels || !data.labels.length) {
    return <p>No data available for the bar chart.</p>; // Display message if no data
  }

  // Calculate the highest occupancy and its corresponding room
  const numericalData = data.datasets[1].data.map(value => parseFloat(value));
  const highestValueIndex = numericalData.indexOf(Math.max(...numericalData));
  const highestValue = numericalData[highestValueIndex];
  const highestLabel = data.labels[highestValueIndex];
  let timeframe;
  if (time[1] > 48 || time[0] > 48) {
    timeframe = `${Math.round((Number(time[1]) - Number(time[0])) / 24)}d`;
  } else if (Number(time[1]) - Number(time[0]) < 1) {
    timeframe = `${(Number(time[1]) - Number(time[0])) * 60 * 60}s`;
  } else {
    timeframe = `${Number(time[1]) - Number(time[0])}hr(s)`;
  };

  //console.log("timeframe", timeframe);

  return (
    <div style={styles.barChartContainer}>
      <h2 style={styles.title}>Occupancy Comparison ({timeframe})</h2>
      <Bar data={data} options={options} /> {/* Render Bar chart */}
      <div style={styles.highestOccupancy}>
        <h4>
          <span style={{ color: color1 }}>{highestLabel}</span> currently has the highest occupancy of {' '}
          <span style={{ color: color1 }}>{highestValue}</span> people.
        </h4>
      </div>
    </div>
  );
};

// Bar chart options: define chart behavior and appearance
const options = {
  responsive: true, // Ensures chart adjusts to screen size
  scales: {
    x: {
      title: {
        display: true, // Show x-axis title
        text: 'Rooms', // Title for x-axis
        font: {
          size: 10
        }
      },
      ticks: {
        font: {
          size: 8
        }
      }
    },
    y: {
      title: {
        display: true, // Show y-axis title
        text: 'Occupancy', // Title for y-axis
        font: {
          size: 10
        }
      },
      ticks: {
        font: {
          size: 8
        }
      },
      beginAtZero: true, // Ensure y-axis starts from 0
    }
  }
};

// Define expected prop types for BarPlot component
BarPlot.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array.isRequired, // Labels are required for the x-axis
    datasets: PropTypes.array.isRequired, // Datasets array is required for the chart
  }), // Ensure that the data prop contains both labels and datasets
  time: PropTypes.string.isRequired, // Time is a required string prop for the title
};

// Define custom inline styles for the bar chart container and title
const styles = {
  barChartContainer: {
    width: '100%', // Ensure the chart takes full width of its container
    height: '70%', // Ensure the chart takes full height
    position: 'relative', // Position relative to allow chart flexibility
  },
  title: {
    color: 'rgba(0, 0, 0, 0.9)', // Dark color for the chart title
  },
  highestOccupancy: {
    color: '#000000'
  }
};

export default BarPlot;
