import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const RealTimeFallPlot = ({ fallData, color2 }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [fallDetected, setFallDetected] = useState(false); // Track if fall is detected

    useEffect(() => {
        const updateChart = () => {
            const labels = fallData.map((data) =>
                new Date(data.timestamp).toLocaleTimeString()
            );
            const fallStatus = fallData.map((data) => data.fall_status);

            // Check if the last fall status is 1 (fall detected)
            setFallDetected(fallStatus[fallStatus.length - 1] === 1);

            setChartData({
                labels: labels, // X-axis: timestamps
                datasets: [
                    {
                        label: "Fall Status",
                        data: fallStatus, // Y-axis: 1 or 0
                        backgroundColor: fallStatus.map((status) =>
                            status === 1 ? color2 : color2
                        ),
                        borderColor: "rgba(0, 0, 0, 0.1)",
                        borderWidth: 1,
                    },
                ],
            });
        };
        updateChart();
    }, [fallData]);

    return (
        <div style={styles.container}>
            {/* Fall Detected Warning Container */}
            {fallDetected && (
                <div style={styles.fallDetectedContainer}>
                    <div style={styles.fallDetectedText}>Fall Detected!</div>
                </div>
            )}
            {/* Chart */}
            <div style={styles.chartWrapper}>
                <Bar
                    data={chartData}
                    style={styles.barChartContainer}
                    options={{
                        responsive: true, // Make the chart responsive
                        maintainAspectRatio: true, // Keep the aspect ratio
                        aspectRatio: 2, // Specify aspect ratio (optional, adjust as needed)
                        scales: {
                            x: {
                                type: "category",
                                title: { display: true, text: "Timestamp" },
                            },
                            y: {
                                title: { display: true, text: "Fall Status" },
                                ticks: {
                                    stepSize: 1,
                                    min: 0,
                                    max: 1,
                                    callback: function (value) {
                                        return value === 1 ? "Fall Detected" : "No Fall"; // Map 1 to "Fall Detected" and 0 to "No Fall"
                                    },
                                },
                            },
                        },
                        animation: { duration: 500 },
                        plugins: {
                            legend: { display: false },
                        },
                    }}
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        position: "relative", // Allow absolute positioning for the warning
    },
    barChartContainer: {
        minHeight: "500px",
        top: "25px",
        width: "100%", // Ensure the chart takes full width of its container
        height: "100%", // Ensure the chart takes full height
        position: "relative", // Position relative to allow chart flexibility
    },
    fallDetectedContainer: {
        position: "absolute", // Position the warning message absolutely
        top: "10px", // Place it at the top
        left: "50%",
        transform: "translateX(-50%)", // Center the warning horizontally
        padding: "10px",
        backgroundColor: "rgba(255, 0, 0, 0.1)",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 10, // Ensure the warning is above the chart
    },
    fallDetectedText: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "red",
    },
    chartWrapper: {
        marginTop: "20px", // Add some space above the chart to prevent overlap
    },
};

export default RealTimeFallPlot;
