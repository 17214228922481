import React, { useState } from "react";

// Function to handle mouse down events for the buttons
const handleMouseDown = (e) => {
  e.stopPropagation(); // Prevent drag from triggering
};

const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex
    .replace(/^#/, "")
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Floorplan = ({ onRoomSelectionChange, color1, color2 }) => {
  const [roomInfo, setRoomInfo] = useState("");
  const [selectedRooms, setSelectedRooms] = useState(["Room 1"]);

  const toggleRoomSelection = (room) => {
    setSelectedRooms((prev) => {
      const updatedRooms = prev.includes(room)
        ? prev.filter((r) => r !== room)
        : [...prev, room];

      onRoomSelectionChange(updatedRooms);
      return updatedRooms;
    });
  };

  const updateRoomInfo = (room) => {
    switch (room) {
      case "Room 1":
        setRoomInfo({
          room: "Room 1",
          description: "Description for F1 area.",
        });
        break;
      case "Room 2":
        setRoomInfo({
          room: "Room 2",
          description: "Description for F2 area.",
        });
        break;
      case "Room 3":
        setRoomInfo({
          room: "Room 3",
          description: "Description for F3 area.",
        });
        break;
      case "Room 4":
        setRoomInfo({
          room: "Room 4",
          description: "Description for F4 area.",
        });
        break;
      case "Room 5":
        setRoomInfo({
          room: "Room 5",
          description: "Description for F5 area.",
        });
        break;
      case "Room 6":
        setRoomInfo({
          room: "Room 6",
          description: "Description for F6 area.",
        });
        break;
      case "Room 7":
        setRoomInfo({
          room: "Room 7",
          description: "Description for F7 area.",
        });
        break;
      default:
        setRoomInfo("");
    }
  };

  return (
    <div
      style={{
        //border: "2px solid #ce3987",
        borderRadius: "8px",
        background: "#ffffff",
        width: "100%", // Takes up full width
        height: "100%", // Takes up full height
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Centering the SVG container
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 800 600"
        style={{
          width: "100%", // Make the SVG responsive
          height: "auto", // Maintain aspect ratio
          maxHeight: "100%", // Ensure it doesn't exceed the parent container's height
        }}
      >
        <image
          href={`${process.env.PUBLIC_URL}/Floor Plan.png`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid meet" // Preserve the aspect ratio
        />

        {[
          // { room: "Room 1", path: "110,390 145,400 145,465 110,480" },
          //{ room: "Room 8", path: "110,292 130,285 130,340 110,340" },
          {
            room: "Room 2",
            path: "80,205 700,205 700,295 80,295",
            label: "2",
            labelX: 400,
            labelY: 250,
          },
          {
            room: "Room 1",
            path: "232,126 290,126 290,200 232,200",
            label: "1",
            labelX: 250,
            labelY: 190,
          },
        ].map(({ room, path, label, labelX, labelY }) => (
          <g key={room}>
            <polygon
              points={path}
              fill={
                selectedRooms.includes(room)
                  ? hexToRgba(color2, 0.8)
                  : "rgba(77, 75, 76, 0.3)"
              }
              stroke={selectedRooms.includes(room) ? color2 : "rgba(77, 75, 76)"}
              strokeWidth="2"
              onMouseDown={handleMouseDown}
              onClick={() => {
                toggleRoomSelection(room);
                if (selectedRooms.includes(room)) {
                  setRoomInfo(""); // Clear room info if deselected
                } else {
                  updateRoomInfo(room);
                }
              }}
              style={{
                cursor: "pointer",
                transition: "fill 0.3s ease, stroke 0.3s ease",
              }}
            />
            {/* Room Label */}
            <text
              x={labelX}
              y={labelY}
              fontSize="20"
              fontWeight="bold"
              textAnchor="middle"
              fill={selectedRooms.includes(room) ? "white" : "black"}
            >
              {label}
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

export default Floorplan;