import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const MapComponent = ({ color1 }) => {
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [loading, setLoading] = useState(true);

  // Custom icon for the marker
  const customIcon = new L.DivIcon({
    className: "custom-div-icon",
    html: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
        <path fill="${color1}" d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z"/>
      </svg>
    `,
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, -36],
  });

  // Fetch the public IP
  const getPublicIP = async () => {
    try {
      const response = await fetch("https://httpbin.org/ip");
      if (!response.ok) throw new Error("Failed to fetch public IP");
      const data = await response.json();
      return data.origin;
    } catch (error) {
      console.error("Error fetching public IP:", error);
      return null;
    }
  };

  // Fetch geolocation based on the IP
  const getGeolocation = async (ipAddress) => {
    try {
      const response = await fetch(`http://ip-api.com/json/${ipAddress}`);
      if (!response.ok) throw new Error("Failed to fetch geolocation");
      const data = await response.json();
      if (data.status === "success") {
        return { lat: data.lat, lon: data.lon };
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
      return null;
    }
  };

  // Fetch IP and geolocation data on mount
  useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true);
      const ipAddress = await getPublicIP();
      if (ipAddress) {
        const location = await getGeolocation(ipAddress);
        if (location) {
          setLat(location.lat);
          setLon(location.lon);
        }
      }
      setLoading(false);
    };

    fetchLocation();
  }, []);

  if (loading) {
    return <div>Loading map...</div>;
  }

  const position = [lat, lon];
  //const position = [25.289010,83.006207];

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} icon={customIcon}>
        <Popup>
          A marker at {lat}, {lon}!
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
