import React from 'react';
import { FaQuestionCircle, FaSignOutAlt } from 'react-icons/fa'; // Importing icons
import { MdOutlineDashboard } from 'react-icons/md';
import { FaPersonFalling } from "react-icons/fa6";

const Sidebar = ({ username, userRole, onLogout, onHelp, setActiveSection, activeSection, color1 }) => {

  const navStyles = {
    marginTop: '12em',//'140px',
    listStyleType: 'none',
    padding: 0,
  };

  const listItemStyles = {
    margin: '1em 0', // Increased margin for spacing
  };

  const linkStyles = {
    textDecoration: 'none',
    color: 'white',
    fontSize: '1.1em',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer', // Add pointer for better UX
    padding: '10px',
    borderRadius: '5px', // Rounded corners for better look
    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  };

  const activeLinkStyles = {
    backgroundColor: '#005BA7', //  background for active link
  };

  const iconStyles = {
    marginRight: '15px', // Adds space between the icon and text
    fontSize: '1.5rem',
  };

  const buttonStyles = {
    padding: '10px',
    backgroundColor: color1,
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px',
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px', // Rounded corners for logout button
    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
  };

  // Function to handle link clicks and set the active section
  const handleLinkClick = (section) => {
    setActiveSection(section); // Update the active section
  };

  return (
    <div className="sidebar">
      <nav>
        <ul style={navStyles}>
          <li style={listItemStyles}>
            <div
              style={{
                ...linkStyles,
                ...(activeSection === 'dashboard' ? activeLinkStyles : {}),
              }}
              onClick={() => handleLinkClick('dashboard')}
            >
              <MdOutlineDashboard style={iconStyles} /> Dashboard
            </div>
          </li>
          {/* <li style={listItemStyles}>
            <div
              style={{
                ...linkStyles,
                ...(activeSection === 'geofencing' ? activeLinkStyles : {}),
              }}
              onClick={() => handleLinkClick('geofencing')}
            >
              <MdOutlineDashboard style={iconStyles} /> Geofencing
            </div>
          </li> */}
          <li style={listItemStyles}>
            <div 
              style={{
                ...linkStyles,
                ...(activeSection === 'fallDetection' ? activeLinkStyles : {}),
              }} 
              onClick={() => handleLinkClick('fallDetection')}
            >
              <FaPersonFalling style={iconStyles} /> Fall Detection
            </div>
          </li>
        </ul>
      </nav>

      <button
        className="help-btn"
        style={buttonStyles}
        onClick={onHelp}
      >
        <FaQuestionCircle style={iconStyles} /> Help
      </button>

      <button
        className="logout-btn"
        style={buttonStyles}
        onClick={onLogout}
      >
        <FaSignOutAlt style={{ marginRight: '10px' }} /> Logout
      </button>
    </div>
  );
};

export default Sidebar;

