import React, { useState } from "react";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signupUsername, setSignupUsername] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState(""); // New state for the signup code
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Handle signup with code
  const handleSignup = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_LOGIN_URL}/api/auth/register-with-code`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: signupUsername,
          password: signupPassword,
          email,
          code, // Send the code here
        }),
      }
    );

    if (response.ok) {
      setSuccessMessage("Signup successful! Please verify your email.");
      setSignupUsername("");
      setSignupPassword("");
      setEmail("");
      setCode(""); // Clear code after successful signup
    } else {
      const errorData = await response.json();
      setError(errorData.message || "Signup failed. Please try again.");
    }
  };

  //login handling
  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_LOGIN_URL}/api/auth/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      }
    );
  
    if (response.ok) {
      const data = await response.json();
      sessionStorage.setItem("token", data.token);
      onLogin(data.username,data.token, data.role, data.layout, data.color1, data.color2, data.geofence, data.selectedPlots);
    } else {
      const errorData = await response.json();
  
      // Handle error based on type
      switch (errorData.type) {
        case "LOCKED":
          setError(`Your account is locked. Try again in ${errorData.lockDurationInMinutes} minutes.`);
          break;
        case "FAILED_ATTEMPTS":
          setError(
            `Invalid credentials. You have ${errorData.remainingAttempts} attempt(s) left before your account is locked.`
          );
          break;
        default:
          setError(errorData.message || "An error occurred. Please try again.");
          break;
      }
    }
  };
  

  const styles = {
    containerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "linear-gradient(to left, #000000, #f3f1f7, #000000)", // gradient
      color: "white",
    },
    formWrapperStyle: {
      display: "flex",
      gap: "50px",
    },
    formContainerStyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      border: "2px solid #FFD44A",
      width: "350px",
    },
    formStyle: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      width: "100%",
    },
    rowStyle: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    labelStyle: {
      flex: "1",
      textAlign: "right",
    },
    inputStyle: {
      flex: "2",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    buttonStyle: {
      padding: "10px",
      backgroundColor: "#FFD44A",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.containerStyle}>
      <div style={styles.formWrapperStyle}>
        {/* Signup Form */}
        <div style={styles.formContainerStyle}>
          <h2>Signup</h2>
          {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}
          <form onSubmit={handleSignup} style={styles.formStyle}>
            <div style={styles.rowStyle}>
              <label style={styles.labelStyle}>Username:</label>
              <input
                type="text"
                placeholder="Username"
                value={signupUsername}
                onChange={(e) => setSignupUsername(e.target.value)}
                style={styles.inputStyle}
                required
              />
            </div>
            <div style={styles.rowStyle}>
              <label style={styles.labelStyle}>Password:</label>
              <input
                type="password"
                placeholder="Password"
                value={signupPassword}
                onChange={(e) => setSignupPassword(e.target.value)}
                style={styles.inputStyle}
                required
              />
            </div>
            <div style={styles.rowStyle}>
              <label style={styles.labelStyle}>Email ID:</label>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.inputStyle}
                required
              />
            </div>
            <div style={styles.rowStyle}>
              <label style={styles.labelStyle}>Signup Code:</label>
              <input
                type="text"
                placeholder="Signup Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                style={styles.inputStyle}
                required
              />
            </div>
            <button type="submit" style={styles.buttonStyle}>
              Signup
            </button>
          </form>
        </div>

        {/* Login Form */}
        <div style={styles.formContainerStyle}>
          <h2>Login</h2>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <form onSubmit={handleLogin} style={styles.formStyle}>
            <div style={styles.rowStyle}>
              <label style={styles.labelStyle}>Username:</label>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={styles.inputStyle}
                required
              />
            </div>
            <div style={styles.rowStyle}>
              <label style={styles.labelStyle}>Password:</label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.inputStyle}
                required
              />
            </div>
            <button type="submit" style={styles.buttonStyle}>
              Login
            </button>
          </form>
          {/* Forgot Password Link */}
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <a
              href="/forgot-password"
              style={{ color: "#000000", textDecoration: "none" }}
            >
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
