import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

// Calculate the time range in hours for display
const getFormattedTime = (hours, timeZone, showTime = true) => {
  const endDate = new Date();
  endDate.setHours(endDate.getHours() - hours);

  // Format the date and time according to the specified timeZone
  const options = { timeZone, year: 'numeric', month: 'long', day: 'numeric' };
  const dateStr = endDate.toLocaleDateString(undefined, options);

  if (showTime) {
    const timeOptions = { timeZone, hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const timeStr = endDate.toLocaleTimeString(undefined, timeOptions);
    return `${dateStr} ${timeStr}`;
  }

  return dateStr; // Return just the date
};

const TimeframeSlider = ({ onTimeframeChange, timeZone = "America/Los_Angeles" }) => {
  const [value, setValue] = useState([0, 1]); // Default range from 1 hour to 2 hours

  // Map the slider's values for non-linear scaling
  const mapSliderValue = (val) => {
    if (val <= 48) return val; // 1-48 hours as is
    return 48 + (val - 48) * 24; // After 48 hours, step by 24 hours (1 day)
  };

  // Reverse map the value back to the slider scale
  const reverseMapSliderValue = (val) => {
    if (val <= 48) return val; // 1-48 hours as is
    return 48 + (val - 48) / 24; // After 48 hours, step by 1 for every 24 hours
  };

  const handleSliderChange = (event, newValue) => {
    // Map values for internal state
    const mappedValue = newValue.map(mapSliderValue);
    if (mappedValue[0] >= mappedValue[1]) {
      return; // Prevent invalid ranges
    }

    setValue(newValue);
    onTimeframeChange(mappedValue); // Pass mapped values to the parent
  };

  const valuetext = (val) => {
    const hours = mapSliderValue(val);
    return hours <= 48 ? `${hours} hours` : `${Math.ceil(hours / 24)} days`;
  };

  // Format values to display the actual time range with timezone consideration
  const startTime = getFormattedTime(mapSliderValue(value[1]), timeZone, true);
  const endTime = getFormattedTime(mapSliderValue(value[0]), timeZone, true);

  return (
    <Box sx={{ width: 300 }}>
      <Slider
        value={value}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        min={0} // Slider starts at 1
        max={58} // Maximum mapped value (48 hours + 10 days * 1 step per day)
        step={1} // Keep step size at 1 for smooth adjustment
        marks={[
          { value: 1 }, //label: '1h' },
          { value: 24 }, //, label: '24h' },
          { value: 48 }, //, label: '48h' },
          { value: 49 }, //, label: '3d' },
          { value: 50 }, //, label: '4d' },
          { value: 51 }, //, label: '5d' },
          { value: 54 }, //, label: '7d' },
          { value: 58 }, //, label: '14d' },
        ]}
        valueLabelFormat={valuetext} // Display the mapped value (hours/days)
        getAriaLabel={() => "Timeframe range"}
      />
      <div className="slider-time">
        {/* Display the actual time range */}
        <span>
          From: {startTime} to {endTime}
        </span>
      </div>
    </Box>
  );
};

const LiveModeSlider = ({ onIntervalChange, timeZone }) => {
    const intervals = [15, 30, 45, 60]; // Seconds (15s, 30s, 45s, 1min)
    const [value, setValue] = useState(15); // Default interval: 15 seconds
  
    const handleSliderChange = (event, newValue) => {
      setValue(newValue);
      onIntervalChange(newValue);
    };
  
    // Get current time and end time based on the interval
    const currentTime = new Date();
    const endTime = new Date(currentTime.getTime() + value * 1000); // Add selected interval in seconds
  
    // Adjust the current and end time for the specified timezone
    const formattedCurrentTime = getFormattedTime(0, timeZone, true);
    const formattedEndTime = getFormattedTime(value, timeZone, true);
  
    return (
      <Box sx={{ width: 300, mt: 4 }}>
        <Slider
          value={value}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={15}
          max={60}
          step={15}
          marks={[
            { value: 15, label: "15s" },
            { value: 30, label: "30s" },
            { value: 45, label: "45s" },
            { value: 60, label: "1m" },
          ]}
        />
        <div className="slider-time">
          {/* Display the current time and end time adjusted for the timezone */}
          <span>
            From: {formattedCurrentTime}
          </span>
        </div>
      </Box>
    );
  };

export { TimeframeSlider, LiveModeSlider };
