import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Rect, Image } from "react-konva";

const DISPLAY_WIDTH = 600; // Display size for user selection
const DISPLAY_HEIGHT = 600;
const ORIGINAL_WIDTH = 884; // Original image size for scaling
const ORIGINAL_HEIGHT = 664;
const DEFAULT_IMAGE = `${process.env.PUBLIC_URL}/room_layout.png`;

const RoomGeofence = ({ username }) => {
    const [geofence, setGeofence] = useState(null);
    const [drawing, setDrawing] = useState(false);
    const [confirmedGeofence, setConfirmedGeofence] = useState(null);
    const [bgImage, setBgImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const stageRef = useRef(null);

    useEffect(() => {
        const fetchRoomImage = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/auth/get-room-image?username=${username}`
                );
                const data = await response.json();
                const imageUrl = data.imageUrl || DEFAULT_IMAGE;

                const img = new window.Image();
                img.src = imageUrl;
                img.onload = () => setBgImage(img);
            } catch (error) {
                console.error("Error fetching room image:", error);
                const img = new window.Image();
                img.src = DEFAULT_IMAGE;
                img.onload = () => setBgImage(img);
            }
        };

        if (username) fetchRoomImage();
    }, [username]);

    useEffect(() => {
        const storedGeofence = sessionStorage.getItem("geofence");
        if (storedGeofence) {
            const parsedGeofence = JSON.parse(storedGeofence);
            const scaleX = DISPLAY_WIDTH / ORIGINAL_WIDTH;
            const scaleY = DISPLAY_HEIGHT / ORIGINAL_HEIGHT;

            setConfirmedGeofence({
                x_center: parsedGeofence.x_center * scaleX,
                y_center: parsedGeofence.y_center * scaleY,
                width: parsedGeofence.width * scaleX,
                height: parsedGeofence.height * scaleY,
            });
        }
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        const formData = new FormData();
        formData.append("image", file);
        formData.append("username", username);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/auth/upload-room-image?username=${username}`,
                { method: "POST", body: formData }
            );
            const data = await response.json();

            if (data.imageUrl) {
                const img = new window.Image();
                img.src = data.imageUrl;
                img.onload = () => setBgImage(img);
                alert("Image uploaded successfully!");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("Failed to upload image. Please try again.");
        } finally {
            setUploading(false);
        }
    };

    const handleMouseDown = (e) => {
        if (drawing || confirmedGeofence) return;
        const { x, y } = e.target.getStage().getPointerPosition();
        setGeofence({ x, y, width: 0, height: 0 });
        setDrawing(true);
    };

    const handleMouseMove = (e) => {
        if (!drawing || !geofence) return;
        const { x, y } = e.target.getStage().getPointerPosition();
        setGeofence((prev) => ({
            ...prev,
            width: x - prev.x,
            height: y - prev.y,
        }));
    };

    const handleMouseUp = () => setDrawing(false);

    const handleSaveGeofence = async (scaledGeofence) => {
        try {
            sessionStorage.setItem("geofence", JSON.stringify(scaledGeofence));
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/auth/save-geofence`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
                    },
                    body: JSON.stringify({ username, geofence: scaledGeofence }),
                }
            );

            if (!response.ok) throw new Error("Failed to save geofence");

            alert("Geofence saved successfully.");
            setConfirmedGeofence({
                x_center: scaledGeofence.x_center * (DISPLAY_WIDTH / ORIGINAL_WIDTH),
                y_center: scaledGeofence.y_center * (DISPLAY_HEIGHT / ORIGINAL_HEIGHT),
                width: scaledGeofence.width * (DISPLAY_WIDTH / ORIGINAL_WIDTH),
                height: scaledGeofence.height * (DISPLAY_HEIGHT / ORIGINAL_HEIGHT),
            });
        } catch (error) {
            console.error("Error saving geofence:", error);
            alert("Failed to save geofence. Please try again.");
        }
    };

    const handleConfirmAndSaveGeofence = () => {
        if (geofence) {
            const scaleX = ORIGINAL_WIDTH / DISPLAY_WIDTH;
            const scaleY = ORIGINAL_HEIGHT / DISPLAY_HEIGHT;

            const scaledGeofence = {
                x_center: Math.round((geofence.x + geofence.width / 2) * scaleX),
                y_center: Math.round((geofence.y + geofence.height / 2) * scaleY),
                width: Math.round(Math.abs(geofence.width) * scaleX),
                height: Math.round(Math.abs(geofence.height) * scaleY),
            };

            handleSaveGeofence(scaledGeofence);
        }
    };

    const handleResetGeofence = () => {
        setGeofence(null);
        setConfirmedGeofence(null);
        sessionStorage.removeItem("geofence");
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <Stage
                ref={stageRef}
                width={DISPLAY_WIDTH}
                height={DISPLAY_HEIGHT}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                style={{ border: "2px solid black", cursor: "crosshair", margin: "auto" }}
            >
                <Layer>
                    <Rect x={0} y={0} width={DISPLAY_WIDTH} height={DISPLAY_HEIGHT} fill="darkblue" />
                    {bgImage && <Image image={bgImage} width={DISPLAY_WIDTH} height={DISPLAY_HEIGHT} />}

                    {/* Draw Geofence (while drawing) */}
                    {geofence && (
                        <Rect
                            x={geofence.x}
                            y={geofence.y}
                            width={geofence.width}
                            height={geofence.height}
                            stroke="red"
                            strokeWidth={2}
                            dash={[10, 5]}
                        />
                    )}

                    {/* Draw Confirmed Geofence */}
                    {confirmedGeofence && (
                        <Rect
                            x={confirmedGeofence.x_center - confirmedGeofence.width / 2}
                            y={confirmedGeofence.y_center - confirmedGeofence.height / 2}
                            width={confirmedGeofence.width}
                            height={confirmedGeofence.height}
                            stroke="green"
                            strokeWidth={2}
                            dash={[10, 5]}
                        />
                    )}
                </Layer>
            </Stage>

            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <input type="file" accept="image/*" onChange={handleImageUpload} disabled={uploading} />
                <button onClick={handleConfirmAndSaveGeofence} disabled={!geofence || confirmedGeofence}>
                    Save Geofence
                </button>
                <button onClick={handleResetGeofence}>
                    Set New Geofence
                </button>
            </div>
        </div>
    );
};

export default RoomGeofence;
