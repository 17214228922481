import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

/**
 * LineChart component renders a line chart and highlights the peak occupancy.
 * If no data is provided, it gracefully handles the situation by rendering an appropriate message.
 * 
 * @param {Object} data - Data object containing labels and datasets for the line chart.
 * @param {string} time - Timeframe for the data (used in the title).
 * @param {string} color1 - Colors for the chart
 */
const LineChart = ({ data, time, color1 }) => {
    // Error handling: Check if data is present and contains valid datasets and labels
    if (!data || !data.datasets || !data.datasets.length || !data.labels || !data.labels.length) {
        return <p>No data available for the line chart.</p>; // Display message if no data
     }

    // Find the maximum occupancy and its corresponding timestamp
    const maxOccupancyData = data.datasets[0].data; // Assumes the first dataset contains occupancy values
    const maxOccupancy = Math.max(...maxOccupancyData);
    const maxIndex = maxOccupancyData.indexOf(maxOccupancy);
    const maxTimestamp = data.labels[maxIndex]; // Assuming labels correspond to timestamps
    let timeframe;
    if (time[1] >  48 || time[0] > 48) {
      timeframe = `${Math.round((Number(time[1]) - Number(time[0]))/24)}d`;
    } else if (Number(time[1])- Number(time[0]) < 1) {
        timeframe = `${(Number(time[1])- Number(time[0]))*60*60}s`;
    }
    else {
      timeframe = `${Number(time[1])- Number(time[0])}hr(s)`;
    };
    
    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Occupancy Line Chart ({timeframe})</h2>
            <Line data={data} options={options(color1)} /> {/* Render Line chart */}
            <div style={styles.maxOccupancy}>
            <h4>Maximum Occupancy of 
                <span style= {{color: color1}}> {maxOccupancy}</span> people at 
                <span style= {{color: color1}}> {maxTimestamp}</span></h4>  
            </div>
        </div>
    );
};

// Line chart options: define chart behavior, tooltip, and point customization
const options = (color1) => ({
    responsive: true, // Ensure the chart adjusts to the screen size
    maintainAspectRatio: false, // Allow the chart to fill the container
    plugins: {
        legend: {
            display: true,
            labels: {
                color: 'rgba(0, 0, 0, 1)', // Legend text color
                generateLabels: (chart) => {
                    const originalLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                    originalLabels.push({
                        text: 'Peak Occupancy',
                        fillStyle: '#000000', // Color for the max point
                        lineWidth: 0,
                        hidden: false,
                        datasetIndex: null, // Custom label for peak occupancy
                    });
                    return originalLabels;
                },
            },
        },
        /* tooltip: {
            backgroundColor: 'rgba(206,57,135,0.9)', // Tooltip background color
            titleColor: 'rgba(255, 255, 255, 0.9)', // Tooltip title text color
            bodyColor: 'rgba(255, 255, 255, 0.9)', // Tooltip body text color
        }, */
        datalabels: {
            display: false, // Disable data labels on points
        },
    },
    scales: {
        x: {
            ticks: {
                color: 'rgba(0, 0, 0, 0.9)', // X-axis ticks color
                font: {
                    size:10
                }
            },
            title: {
                display: true,
                text: 'Timestamp', // Label for the x-axis
                font: {
                    size:10
                }
            }
        },
        y: {
            ticks: {
                color: 'rgba(0, 0, 0, 0.9)', // Y-axis ticks color
                font: {
                    size:10
                }
            },
            title: {
                display: true,
                text: 'Occupancy', // Label for the y-axis
                font: {
                    size:10
                }
            }
        },
    },
    elements: {
        point: {
            pointStyle: 'circle', // Points are displayed as circles
            pointBorderWidth: 2, // Border width for the points
            pointBackgroundColor: (context) => {
                const dataset = context.dataset.data;
                const index = context.dataIndex;
                const maxValue = Math.max(...dataset);
                return dataset[index] === maxValue ? '#000000' : color1; 
            },
            pointBorderColor: (context) => {
                const dataset = context.dataset.data;
                const index = context.dataIndex;
                const maxValue = Math.max(...dataset);
                return dataset[index] === maxValue ? '#000000' : color1; 
            },
            pointRadius: (context) => {
                const dataset = context.dataset.data;
                const index = context.dataIndex;
                const maxValue = Math.max(...dataset);
                return dataset[index] === maxValue ? 6.5 : 5; // Larger point for the max value
            }
        },
    },
});

// Styles for the LineChart component
const styles = {
    container: {
        position: 'relative', // Relative position for scaling
        //minHeight: '275px', // Set a fixed height for the chart container
        width: '100%', // Full width of the parent container
        height: '75%',
    },
    title: {
        color: 'rgba(0, 0, 0, 0.9)', // Color for the title text
    },
    maxOccupancy: {
        marginTop: '5px', // Add margin above the peak occupancy text
        fontWeight: 'bold', // Bold font for emphasis
        color: 'rgba(0, 0, 0, 0.7)', // Slightly lighter color for the text
    },
};

export default LineChart;
