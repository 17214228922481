import React from 'react';
import PropTypes from 'prop-types';

const CalendarPlot = ({ data, year, month, color1 }) => {
    /**
     * Calculate the daily average occupancy for the given year and month.
     * This function ensures that no duplicate timestamps are counted.
     * 
     * @returns {Array} - An array of objects where each object contains the day and its average occupancy.
     */
    const daysOfWeek = ['Su','M', 'T', 'W', 'Th', 'F', 'S'];
    const getDailyAverageOccupancy = () => {
        const dailyData = {};
        const seenTimestamps = {};

        data.forEach(entry => {
            const date = new Date(entry.timestamp);
            const entryYear = date.getFullYear();
            const entryMonth = date.getMonth();

            // Filter data based on the provided year and month
            if (entryYear === year && entryMonth === month) {
                const day = date.getDate();
                const timestampKey = date.toISOString();

                // Initialize daily data and seen timestamps for the day
                if (!dailyData[day]) {
                    dailyData[day] = { total: 0, count: 0 };
                    seenTimestamps[day] = new Set();
                }
                // Check if this timestamp has already been processed for the day
                if (!seenTimestamps[day].has(timestampKey)) {
                    dailyData[day].total += Number(entry.occupancy);
                    dailyData[day].count += 1;
                    seenTimestamps[day].add(timestampKey); // Mark this timestamp as seen
                } else {
                    dailyData[day].total += Number(entry.occupancy);
                }
            }
        });

        // Calculate daily averages and format the result
        const dailyAverages = Object.keys(dailyData).map(day => {
            const { total, count } = dailyData[day];
            const averageOccupancy = count > 0 ? total / count : 0; // Avoid division by 0
            return {
                day: Number(day),
                average: averageOccupancy,
            };
        });

        return dailyAverages;
    };

    const dailyAverages = getDailyAverageOccupancy();

    // Extract occupancy values to find the min and max
    const occupancyValues = dailyAverages.map(d => d.average);
    const minOccupancy = Math.min(...occupancyValues);
    const maxOccupancy = Math.max(...occupancyValues);

    /**
     * Generate a magenta shade based on the occupancy level.
     * The color becomes darker with higher occupancy.
     * 
     * @param {number} occupancy - The occupancy value to determine the color.
     * @returns {string} - The corresponding HSL color value.
     */
    const hexToRgba = (hex, alpha = 1) => {
        // Remove the '#' if present
        hex = hex.replace(/^#/, "");
      
        // Parse the hex color into RGB components
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
      
        // Return the RGBA string
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      };
      
    const hexToHue = (hex) => {
        // Remove the '#' if present
        hex = hex.replace(/^#/, "");
      
        // Parse the hex color into RGB components
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
      
        // Convert RGB to [0, 1] range
        const rNorm = r / 255;
        const gNorm = g / 255;
        const bNorm = b / 255;
      
        // Find min and max RGB values
        const max = Math.max(rNorm, gNorm, bNorm);
        const min = Math.min(rNorm, gNorm, bNorm);
      
        // Compute hue
        let hue = 0;
        if (max === rNorm) {
          hue = (gNorm - bNorm) / (max - min);
        } else if (max === gNorm) {
          hue = 2 + (bNorm - rNorm) / (max - min);
        } else if (max === bNorm) {
          hue = 4 + (rNorm - gNorm) / (max - min);
        }
      
        hue = Math.round(hue * 60); // Convert to degrees
        if (hue < 0) hue += 360;
      
        return hue; // Return only the hue value
      };
      
    const getMagentaShade = (occupancy) => {
        const saturation = 80;
        // Handle N/A cases (e.g., null, undefined, or other non-number values)
        if (occupancy === null || occupancy === undefined || isNaN(occupancy)) {
            return 'hsl(0, 0%, 100%)'; // White for N/A
        }
        const normalizedValue = occupancy <= minOccupancy ? 0 : (occupancy - minOccupancy) / (maxOccupancy - minOccupancy);
        const lightness = Math.max(85 - normalizedValue * 40, 20);
        return `hsl(${hexToHue(color1)}, ${saturation}%, ${lightness}%)`;
    };

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center', // Center the calendar and legend horizontally
            alignItems: 'flex-start', // Align items to the top
            height: '70%', // Full height
            width: '100%'
        },
        calendarContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: '5px',
            margin: '0 20px', // Horizontal space for the legend
            height: '80%', // Full height
            //width: '100%'
        },
        dayBox: {
            border: `1px solid ${hexToRgba(color1, 0.8)}`,
            borderRadius: '4px',
            padding: '3px',
            textAlign: 'center',
            height: 'auto',
            width: 'auto',
            position: 'relative',
            transition: 'background-color 0.3s',
            aspectRatio: 1,
            display: 'flex',
            flexGrow: 1,

        },
        dayLabel: {
            fontSize: 'clamp(0.01rem, 0.7vw, 2rem)',
            fontWeight: 'bold',
            color: 'white',
        },
        occupancyText: {
            color: 'white',
            fontWeight: 'bold',
        },
        legend: {
            color: 'black',
            fontSize: '0.9em',
            marginTop: '20px',
        },
        legendItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '5px',
        },
        colorBox: {
            width: '20px',
            height: '20px',
            marginRight: '10px',
        },
        noData: {
            color: color1,
            fontSize: '1.5em',
            textAlign: 'center',
            marginTop: '20px',
        },
    };

    // Determine the starting day of the week for the month
    const firstDay = new Date(year, month, 1).getDay();
    const startOffset = firstDay;
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const grid = Array.from({ length: startOffset + daysInMonth }, (_, index) => {
        const day = index - startOffset + 1;
        return day > 0 ? day : null;
    });
    
    return (
        <div style={styles.container}>
            {dailyAverages.length === 0 ? (
                <div style={styles.noData}>No data available for the selected month</div>
            ) : (
                <>
                    <div style={styles.calendarContainer}>
                    {daysOfWeek.map(day => (
                            <div key={day} style={styles.headerBox}>{day}</div>
                        ))}
                        {/*{Array.from({ length: 31 }, (_, index) => {
                            const day = index + 1;*/}
                        {grid.map((day,index) => {
                            if (!day) {
                                // Skip rendering for non-existing days
                                return <div key={index} style={{ visibility: 'hidden' }}></div>;
                            }
                            const dailyAverage = dailyAverages.find(d => d.day === day);
                            const occupancy = Math.round(dailyAverage ? dailyAverage.average : 0);
                            //const backgroundColor = getMagentaShade(occupancy);
                            const backgroundColor = index % 7 === 0 || index % 7 === 6 // Check if Saturday or Sunday
                                ? 'white' // White background for weekends
                                : getMagentaShade(occupancy);
                            const textColor = index % 7 === 0 || index % 7 === 6 // Check if Saturday or Sunday
                                ? 'black' // Black text for weekends
                                : 'white';

                            return (
                                <div
                                    //key={day}
                                    key={`day-${year}-${month}-${day}`}
                                    style={{
                                        ...styles.dayBox,
                                        backgroundColor,
                                        border: day ? styles.dayBox.border : 'none',
                                    }}
                                >
                                    {day && <div style={{...styles.dayLabel, color: textColor}}>{day}</div>}
                                </div>
                            );
                        })}
                    </div>

                    <div style={styles.legend}>
                        <h4 style={{ color: 'rgba(255, 255, 255, 0.9)' }}>Occupancy Hue Legend</h4>
                        <div style={styles.legendItem}>
                            <div style={{ ...styles.colorBox, backgroundColor: getMagentaShade(minOccupancy) }}></div>
                            <span>Low Occupancy</span>
                        </div>
                        <div style={styles.legendItem}>
                            <div style={{ ...styles.colorBox, backgroundColor: getMagentaShade((minOccupancy + maxOccupancy) / 2) }}></div>
                            <span>Medium Occupancy</span>
                        </div>
                        <div style={styles.legendItem}>
                            <div style={{ ...styles.colorBox, backgroundColor: getMagentaShade(maxOccupancy) }}></div>
                            <span>High Occupancy</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

CalendarPlot.propTypes = {
    data: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
};

export default CalendarPlot;
