// ResetPassword.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ResetPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const token = params.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setError("Invalid or expired token.");
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${process.env.REACT_APP_API_LOGIN_URL}/api/auth/reset-password`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, password: newPassword }),
      }
    );

    if (response.ok) {
      setSuccessMessage("Password has been successfully reset.");
      setTimeout(() => navigate("/"), 3000); // Redirect to login after 3 seconds
    } else {
      const errorData = await response.json();
      setError(errorData.message || "Failed to reset password.");
    }
  };

  const styles = {
    containerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "linear-gradient(to left, #005BA7, #f3f1f7, #005BA7)",
      color: "white",
    },
    formContainerStyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      border: "2px solid #005BA7",
      width: "350px",
    },
    formStyle: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      width: "100%",
    },
    inputStyle: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    buttonStyle: {
      padding: "10px",
      backgroundColor: "#005BA7",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.containerStyle}>
      <div style={styles.formContainerStyle}>
        <h2>Reset Password</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
        <form onSubmit={handleSubmit} style={styles.formStyle}>
          <div style={{ marginBottom: "15px" }}>
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
              style={styles.inputStyle}
              required
            />
          </div>
          <button type="submit" style={styles.buttonStyle}>
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
